/**
 * Converts a JSON date (ISO 8601) into different constellations of the date
 * @param {string} dateString - ISO8601 date string
 * @returns {convertJsonDateReturnObject} - object with the different date string constellations
 */
function convertJsonDate(dateString) {
  if (dateString) {
    const convertedString = dateString.replace('Z', '').replace('T', ' ');
    const date = convertedString.substr(0, convertedString.indexOf(' '));
    const time = convertedString.substr(convertedString.indexOf(' ') + 1);

    return {
      fullMs: convertedString,
      full: `${date} ${time.substr(0, time.indexOf('.'))}`,
      date,
      timeMs: time,
      time: time.substr(0, time.indexOf('.')),
    };
  }
  /**
   * @typedef convertJsonDateReturnObject
   * @property {string} fullMs - full date string with time and milliseconds
   * @property {string} full - full date string with time
   * @property {string} date - full date string
   * @property {string} timeMs - time and milliseconds
   * @property {string} time - time
   */
  return {
    fullMs: 'not provided',
    full: 'not provided',
    date: 'not provided',
    timeMs: 'not provided',
    time: 'not provided',
  };
}

const dateFormatter = {
  convertJsonDate,
};

module.exports = dateFormatter;
