import httpClient from "../httpClient/httpClient";

const SystemUsersService = {
  finishRegistraitonAuth(username, password) {
    return httpClient.post("api/finish-registration-auth", {
      username,
      password
    });
  },
  updatePassword(username, password, newPassword) {
    return httpClient.post("api/update-system-user-password", {
      username,
      password,
      newPassword
    });
  },
  updatePasswordBySystemUserId(oldPassword, newPassword) {
    return httpClient.post("api/update-password", {
      oldPassword,
      newPassword
    });
  },
  sendResetPasswordMail(username, email) {
    return httpClient.post("api/send-reset-password-mail", {
      username,
      email
    });
  },
  resetPassword(SystemUserID, newPassword) {
    return httpClient.post("api/reset-password", {
      SystemUserID,
      newPassword
    });
  },
  systemUserLogout() {
    return httpClient.get("api/system-user-logout");
  },
  systemUserForceLogout(getSystemUserID) {
    return httpClient.get("api/system-user-force-logout", {
      params: {
        getSystemUserID
      }
    });
  },
  systemUserAuthenticate(username, password, tfaToken) {
    return httpClient.post("api/system-user-authenticate", {
      username,
      password,
      tfaToken
    });
  },
  systemUserAuthenticateRecovery(username, password, tfaRecoveryCode) {
    return httpClient.post("api/system-user-authenticate-recovery", {
      username,
      password,
      tfaRecoveryCode
    });
  },
  checkUserPassword(password) {
    return httpClient.post("api/check-user-password", {
      password
    });
  },
  getQRandRecoveryCodes2FA() {
    return httpClient.get("api/get-QR-recovery-codes-2FA", {});
  },
  getSystemUsersByOrganization(organizationID) {
    return httpClient.get("api/get-system-users-by-organization", {
      params: {
        organizationID
      }
    });
  },
  getSystemUserNames() {
    return httpClient.get("api/get-system-user-names");
  },
  resendRegistrationEmail(getSystemUserID) {
    return httpClient.get("api/resend-registration-email", {
      params: {
        getSystemUserID
      }
    });
  },
  getUsers() {
    return httpClient.get("api/get-all-users", {});
  },
  getUsersFiltered(roleFilter) {
    return httpClient.get("api/get-all-users", {
      params: {
        roleFilter
      }
    });
  },
  getUsersSortedByRoles() {
    return httpClient.get("api/get-all-users-sorted-by-roles", {});
  },
  deleteUser(userID) {
    return httpClient.post("api/delete-user", {
      userID
    });
  },
  getSystemUserByID(userID) {
    return httpClient.get("api/get-system-user-by-ID", {
      params: {
        userID
      }
    });
  },
  getOwnSystemUserData(userID) {
    return httpClient.get("api/get-own-system-user-data", {
      params: {
        userID
      }
    });
  },
  getRolesByUserID(userID) {
    return httpClient.get("api/get-roles-by-userID", {
      params: {
        userID
      }
    });
  },
  updateSystemUser(userID, username, email, firstName, lastName, active, SystemRoleIDs) {
    return httpClient.post("api/update-system-user", {
      userID,
      username,
      email,
      firstName,
      lastName,
      active,
      SystemRoleIDs
    });
  },
  registerSystemUser(username, email, firstName, lastName, SystemRoleIDs, active) {
    return httpClient.post("api/register-system-user", {
      username,
      email,
      firstName,
      lastName,
      SystemRoleIDs,
      active
    });
  },
  getCurrentUserPermissions() {
    return httpClient.get("api/get-user-permissions", {});
  },
  getUserPermissions(userID) {
    return httpClient.get("api/get-user-permissions", {
      params: {
        userID
      }
    });
  },
  checkPermission(userID, permission) {
    return httpClient.get("api/check-permission", {
      params: {
        userID,
        permission
      }
    });
  }
};

export default SystemUsersService;
