import axios from 'axios';
import router from '../router';
import store from '../store';

const httpClientConfig = require('../../configs/httpClient.config');

/* ************************************************************************************************
 * INTERCEPTORS
 ************************************************************************************************ */
/* Response interceptors ************************************************************************ */
/**
 * Intercepts every failing response to handle 401 responses and redirect them to login page
 * @param err
 * @returns {Promise<never>}
 */
function errorResponse(err) {
  const { response } = err;
  // eslint-disable-next-line no-underscore-dangle
  if (
    response &&
    response.status &&
    response.status === 401 &&
    err &&
    err.config &&
    // eslint-disable-next-line no-underscore-dangle
    !err.config.__isRetryRequest
  ) {
    // Check if the session is locked due to an idle state
    // If not redirect to the login page
    // If so don't do anything because the sessionLock view is visible
    if (!store.getters['sysAuth/getSessionLock'] && !store.getters['sysAuth/getRegistrationProcessState']) {
      router.push('/pages/login2FA');
    }
    return Promise.reject(err);
  }

  return Promise.reject(err);
}
/**
 * Intercepts every successful response to handle and passes through the result
 * @param response
 * @returns {Promise<unknown>}
 */
function successResponse(response) {
  return Promise.resolve(response);
}

/* Request interceptors ************************************************************************* */
/**
 * Intercepts every request and adds the systemUserID of the currently logged in
 * systemUser to the query
 * @param config
 * @returns {{params}|*}
 */
function addSystemUserID(config) {
  // Check if a systemUser is logged in
  if (store.getters['sysAuth/isLoggedIn']) {
    // Check if the request already has query parameters
    if (config.params) {
      // If so, add the systemUserID
      config.params.systemUserID = store.getters['sysAuth/getUser'];
    } else {
      // If not, add the systemUserID as property of a new params object
      config.params = { systemUserID: store.getters['sysAuth/getUser'] };
    }
  }

  return config;
}

/* ************************************************************************************************
 * HTTP CLIENT CREATION
 ************************************************************************************************ */
/**
 * httpClient directed to the backendUrl + backendPort defined in the httpClient config
 * @type {AxiosInstance}
 */
const httpClient = axios.create({
  baseURL: [httpClientConfig.backendUrl, httpClientConfig.backendPort].join(':'),
  withCredentials: true, // Needed to send cookies with every request
});

/* Interception linking ************************************************************************ */
// set the response interceptors
httpClient.interceptors.response.use(successResponse, errorResponse);
// set the request interceptors
httpClient.interceptors.request.use(addSystemUserID);

export default httpClient;
