const localStorageSystemUserKey = 'lsSystemUser';
const localStorageSystemUserPermissions = 'lsSystemUserPermissions';
const localStorageManager = require('../functions/localStorageManager');
// const lsSystemUser = localStorage.getItem(localStorageSystemUserKey);
const lsSystemUser = parseInt(localStorageManager.readFromStorage(localStorageSystemUserKey), 10);
const lsSystemUserPermissions = JSON.parse(localStorageManager.readFromStorage(localStorageSystemUserPermissions));
// If there is a systemUser in localStorage set login true and store user data
// If not login is false and user is null
const initState = lsSystemUser
  ? { li: true, lsysu: lsSystemUser, sl: false, up: lsSystemUserPermissions }
  : {
      li: false,
      lsysu: null,
      sl: false,
      rp: false,
      up: [],
    };

function updatelocalStorage(state) {
  // localStorage.setItem(localStorageSystemUserKey, JSON.stringify(state.lsysu));
  localStorageManager.writeToStorage(localStorageSystemUserKey, JSON.stringify(state.lsysu));
  localStorageManager.writeToStorage(localStorageSystemUserPermissions, JSON.stringify(state.up));
}

export const sysAuth = {
  namespaced: true,
  state: initState,
  mutations: {
    loginSuccess(state, userInformation) {
      state.li = true;
      state.lsysu = userInformation.user;
      state.sl = false;
      state.up = userInformation.userPermissions;
      updatelocalStorage(state);
    },
    loginFailure(state) {
      state.li = false;
      state.lsysu = null;
      state.sl = false;
      state.up = [];
      updatelocalStorage(state);
    },
    /**
     * @param state
     * @param store - if store is defined: remove all selected and in store saved properties (see: selectedProperties.store.js)
     */
    logout(state) {
      state.li = false;
      state.lsysu = null;
      state.sl = false;
      state.up = [];
      this.commit('selectedProperties/logout');
      updatelocalStorage(state);
    },
    lockSession(state) {
      state.sl = true;
      // updatelocalStorage(state);
    },
    unlockSession(state) {
      state.sl = false;
    },
    registrationProcessActive(state) {
      state.rp = true;
    },
    registrationProcessInactive(state) {
      state.rp = false;
    },
    updateUserPermissions(state, permissions) {
      state.up = permissions;
      updatelocalStorage(state);
    },
  },
  getters: {
    getUser: (state) => {
      return state.lsysu;
    },
    isLoggedIn: (state) => {
      return state.li;
    },
    getSessionLock: (state) => {
      return state.sl;
    },
    getRegistrationProcessState: (state) => {
      return state.rp;
    },
    getUserPermissions: (state) => {
      return state.up;
    },
  },
};
