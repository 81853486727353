const localStorageManager = require('../../functions/localStorageManager');

const initialState = () => {
  const localStorageItem = JSON.parse(localStorageManager.readFromStorage('local'));
  if (localStorageItem) {
    return localStorageItem;
  }
  localStorageManager.writeToStorage('local', JSON.stringify({ selectedLocal: 'gb', selectedDateFormat: 'MDY' }));
  return { selectedLocal: 'gb', selectedDateFormat: 'MDY' };
};

export const locales = {
  namespaced: true,
  state: initialState,
  mutations: {
    setLocal(state, local) {
      state.selectedLocal = local.iso;
      state.selectedDateFormat = local.dateFormat;
      localStorageManager.writeToStorage('local', JSON.stringify(state));
    },
  },
  getters: {
    selectedLocal(state) {
      return state;
    },
  },
};
