<template>
  <div v-if="startProgressBar">
    <v-row class="pa-5">
      <v-progress-linear indeterminate :color="progressBarColor" />
    </v-row>
    <v-row justify="center">
      <p class="text-body-1">{{ progressBarText }}</p>
    </v-row>
  </div>
</template>

<script>
export default {
  name: 'MexLoadingProgress',
  props: {
    startProgressBar: {
      type: Boolean,
      default: false,
    },
    progressBarColor: {
      type: String,
      default: 'primary',
    },
    progressBarText: {
      type: String,
      default: 'Loading ...',
    },
  },
};
</script>

<style></style>
