const colorBase = {
  light_grey: '#B5B4B4',
  light_silver: '#D0D0D0',
  light_silverblue: '#CFCFCF',
  light_oldWhite: '#E5E5E5',
  light_green: '#64DF6A',
  light_blue: '#71B3E8',
  light_orange: '#F4B86C',
  light_red: '#FC6666',
  light_whiteblue: '#F0FBFF',
  light_yellow: '#fff7a8',
  dark_plainblue: '#1F384B',
  dark_silverblue: '#315979',
  dark_plaingrey: '#4E4E4E',
  dark_plainsilver: '#5E636D',
  dark_green: '#0B6E0F',
  dark_blue: '#0E4673',
  dark_orange: '#A56412',
  dark_red: '#AC0000',
  dark_plainblack: '#130E0E',
  dark_yellow: '#544c00',
};
const meditexLicenseServerColorTheme = {
  light: {
    primary: '#56ba9e',
    primaryAccent: '#1f8776',
    secondary: '#7ebeff',
    secondaryAccent: '#e7ffff',
    background: '#a7c2d3',
    foreground: '#f5f5f5',
    success: colorBase.light_green,
    warning: colorBase.light_orange,
    error: colorBase.light_red,
    info: colorBase.light_blue,
    note: '#ffe082',
  },
  dark: {
    primary: '#1c5f51',
    primaryAccent: '#1f8776',
    secondary: '#002037',
    secondaryAccent: '#00324b',
    background: '#283237',
    foreground: '#39454a',
    success: colorBase.dark_green,
    warning: colorBase.dark_orange,
    error: colorBase.dark_red,
    info: colorBase.dark_blue,
    note: '#caae53',
  },
};

export default meditexLicenseServerColorTheme;
