<template>
  <v-navigation-drawer :temporary="navBarTemporary"
                       :expand-on-hover="navBarExpandOnHover" clipped app v-model="drawerToggle" :color="navBarColor" width="300px">
    <!-- NAV BAR HEADER -->
    <v-list-item v-if="navBarHeader.useHeader">
      <v-list-item-icon v-if="navBarHeader.useIcon">
        <v-icon>
          {{ navBarHeader.icon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{ navBarHeader.title }}
        </v-list-item-title>
        <v-list-item-subtitle v-if="navBarHeader.useSubtitle">
          {{ navBarHeader.subtitle }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-divider v-if="navBarHeader.useDivider"></v-divider>
    <!-- NAV BAR LIST -->
    <v-list dense>
      <template v-for="(item, i) in computedItems">
        <v-divider v-if="item.divider" :key="`divider-${i}`" class="ml-4 mr-4" />
        <base-item-group v-else-if="item.children" :key="`group-${i}`" :item="item" @base-item-click="$emit('navbar-click', item)"> </base-item-group>
        <base-item v-else :key="`item-${i}`" :item="item" @base-item-click="$emit('navbar-click', item)"> </base-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'MexNavBar',
  components: {
    BaseItem: () => import('../CtComponents/Item.vue'),
    BaseItemGroup: () => import('../CtComponents/ItemGroup.vue'),
  },
  props: {
    drawerToggle: {
      type: Boolean,
      default: false,
    },
    navBarTemporary: {
      type: Boolean,
      default: false
    },
    navBarExpandOnHover: {
      type: Boolean,
      default: false
    },
    navBarColor: {
      type: String,
      default: 'primary',
    },
    navBarHeader: {
      type: Object,
      default: () => ({
        useHeader: true,
        title: 'Nav Bar Title',
        useIcon: true,
        icon: 'mdi-head-snowflake-outline',
        useSubtitle: true,
        subtitle: 'Nav Bar Subtitle',
        useDivider: true,
      }),
    },
    navBarItems: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    computedItems() {
      return this.navBarItems.map(this.mapItem);
    },
  },
  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: item.title,
      };
    },
  },
};
</script>

<style></style>
