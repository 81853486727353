import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import requiredPermissions from '../requiredPermissions';

Vue.use(VueRouter);

const routes = [
  {
    name: 'ParamsReload',
    path: '/params-reload',
    component: () => import('../views/ParamsReload'),
  },
  {
    name: 'Home',
    path: '/',
    component: () => import('../pages/Home'),
  },
  {
    name: 'PageIndex',
    path: '/pages',
    component: () => import('../pages/PageIndex'),
    children: [
      {
        name: 'Login2FA',
        path: 'login2fa',
        component: () => import('../pages/Login2FA'),
      },
      {
        name: 'FinishRegistration',
        path: 'finishRegistration',
        component: () => import('../pages/FinishRegistration'),
      },
      {
        name: 'FinishRegistrationExpired',
        path: 'finishRegistrationExpired',
        component: () => import('../pages/FinishRegistrationExpired'),
      },
      {
        name: 'Logout',
        path: 'logout',
        component: () => import('../pages/Logout'),
      },
      {
        name: "No Access",
        path: "noaccess",
        component: () => import("../pages/NoAccess")
      },
      {
        name: "ResetPassword",
        path: "resetPassword",
        component: () => import("../pages/ResetPassword")
      }
    ]
  },
  {
    name: 'ViewIndex',
    path: '/views',
    component: () => import('../views/ViewIndex'),
    children: [
      {
        name: 'SystemUserDashboard',
        path: 'system-user-dashboard',
        component: () => import('../views/SystemUserDashboard'),
        meta: {
          requiresAuth: true,
          hardReload: true,
        },
      },
      {
        name: 'RegisterUser',
        path: 'system/user-role-administration/register-user',
        component: () => import('../views/System/RegisterUser'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.registerUser,
          hardReload: true,
        },
      },
      {
        name: 'UserView',
        path: 'system/user-role-administration/user-view',
        component: () => import('../views/System/UserView'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.userView,
        },
      },
      {
        name: 'UsersOverview',
        path: 'system/user-role-administration/users-overview',
        component: () => import('../views/System/UsersOverview'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.userOverview,
        },
      },
      {
        name: 'AddRole',
        path: 'system/user-role-administration/add-role',
        component: () => import('../views/System/AddRole'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.addRole,
        },
      },
      {
        name: 'RoleView',
        path: 'system/user-role-administration/role-view',
        component: () => import('../views/System/RoleView'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.roleView,
        },
      },
      {
        name: 'RolesOverview',
        path: 'system/user-role-administration/roles-overview',
        component: () => import('../views/System/RolesOverview'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.roleOverview,
          hardReload: true,
        },
      },
      {
        name: 'SystemSettings',
        path: 'system/system-settings',
        component: () => import('../views/System/SystemSettings'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.systemSettings,
          hardReload: true,
        },
      },
      {
        name: 'FailedAuthenticationLogs',
        path: 'system/failed-authentication-logs',
        component: () => import('../views/System/FailedAuthenticationLogs'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.systemSettings,
          hardReload: true,
        },
      },
      {
        name: 'MacAddressBlocklist',
        path: 'system/mac-address-blocklist',
        component: () => import('../views/System/MacAddressBlocklist'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.systemSettings,
          hardReload: true,
        },
      },
      {
        name: 'ClinicUUIDBlocklist',
        path: 'system/clinic-uuid-blocklist',
        component: () => import('../views/System/ClinicUUIDBlocklist'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.systemSettings,
          hardReload: true,
        },
      },
      {
        name: 'ClinicsOverview',
        path: 'customer-administration/clinics/clinics-overview',
        component: () => import('../views/ClinicAdministration/ClinicsOverview'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.clinicAdministration,
          hardReload: true,
        },
      },
      {
        name: 'ClinicView',
        path: 'customer-administration/clinics/clinic-view',
        component: () => import('../views/ClinicAdministration/ClinicView'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.clinicAdministration,
        },
      },
      {
        name: 'ClinicViewManuallyManaged',
        path: 'customer-administration/clinic-view-manually-managed',
        component: () => import('../views/ClinicAdministration/ClinicViewManuallyManaged'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.manuallyManagedClinics,
        }
      },
      {
        name: 'ClinicEndpointsView',
        path: 'customer-administration/clinics/clinic-endpoints-view',
        component: () => import('../views/ClinicAdministration/ClinicEndpointsView'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.clinicAdministration,
        },
      },
      {
        name: 'OrganizationOverview',
        path: 'customer-administration/organizations/organizations-overview',
        component: () => import('../views/OrganizationAdministration/OrganizationsOverview'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.organizationAdministration,
          hardReload: true,
        },
      },
      {
        name: 'OrganizationView',
        path: 'customer-administration/organizations/organization-view',
        component: () => import('../views/OrganizationAdministration/OrganizationView'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.organizationAdministration,
        },
      },
      {
        name: 'LocationsOverview',
        path: 'customer-administration/locations/locations-overview',
        component: () => import('../views/LocationAdministration/LocationsOverview'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.locationAdministration,
          hardReload: true,
        },
      },
      {
        name: 'LocationView',
        path: 'customer-administration/locations/location-view',
        component: () => import('../views/LocationAdministration/LocationView'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.locationAdministration,
        },
      },
      {
        name: 'RentalLicenseSettings',
        path: 'license-administration/rental/rental-license-settings',
        component: () => import('../views/LicenseAdministration/RentalLicenseSettings'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.rentalLicense,
        },
      },
      {
        name: 'RentalLicenseView',
        path: 'license-administration/rental/rental-license-view',
        component: () => import('../views/LicenseAdministration/RentalLicenseView'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.rentalLicense,
        },
      },
      {
        name: 'RentalLicenseTemplateSettings',
        path: 'license-administration/rental/rental-license-template-settings',
        component: () => import('../views/LicenseAdministration/RentalLicenseTemplateSettings'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.rentalLicense,
        },
      },
      {
        name: 'FeatureLicenseView',
        path: 'license-administration/feature/feature-license-view',
        component: () => import('../views/LicenseAdministration/FeatureLicenseView'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.featureLicense,
        },
      },
      {
        name: 'FeatureLicenseSettings',
        path: 'license-administration/feature/feature-license-settings',
        component: () => import('../views/LicenseAdministration/FeatureLicenseSettings'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.featureLicense,
        },
      },
      {
        name: 'ClinicCycleTypesStatistics',
        path: 'statistics/cycle-types/clinic-based-statistics',
        component: () => import('../views/Statistics/ClinicCycleTypesStatistics'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.cycleTypesStatistics,
        },
      },
      {
        name: 'YearlyCycleGroupStatistics',
        path: 'statistics/cycle-types/yearly-cycle-group-statistics',
        component: () => import('../views/Statistics/YearlyCycleGroupStatistics'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.cycleTypesStatistics,
        },
      },
      {
        name: 'ClinicEndpointsStatistics',
        path: 'statistics/endpoints/clinic-based-statistics',
        component: () => import('../views/Statistics/ClinicEndpointsStatistics'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.endpointsStatistics,
        },
      },
      {
        name: 'GlobalStatistics',
        path: 'statistics/global-statistics',
        component: () => import('../views/Statistics/GlobalStatistics'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.statistics,
          hardReload: true,
        },
      },
      {
        name: 'SqlStatements',
        path: 'sql-broadcaster/sql-statements',
        component: () => import('../views/SqlBroadcaster/SqlStatements'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.sqlBroadcaster,
          hardReload: true,
        },
      },
      {
        name: 'SqlStatementEditor',
        path: 'sql-broadcaster/sql-statement-editor',
        component: () => import('../views/SqlBroadcaster/SqlStatementEditor'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.sqlBroadcaster,
        },
      },
      {
        name: 'SqlStatementResults',
        path: 'sql-broadcaster/sql-statement-results',
        component: () => import('../views/SqlBroadcaster/SqlStatementResults'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.sqlBroadcaster,
          hardReload: true,
        },
      },
      {
        name: 'SqlStatementHelp',
        path: 'sql-broadcaster/sql-statement-help',
        component: () => import('../views/SqlBroadcaster/SqlStatementHelp'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.sqlBroadcaster,
          hardReload: true,
        },
      },
      {
        name: 'ProductInfoView',
        path: 'product-info/product-info-view',
        component: () => import('../views/ProductInfo/ProductInfoView.vue'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.keyTEXAll,
          hardReload: true,
        },
      },
      {
        name: 'ProductInfoOverview',
        path: 'product-info/product-info-overview',
        component: () => import('../views/ProductInfo/ProductInfoOverview.vue'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.keyTEXAll,
          hardReload: true,
        },
      },
      {
        name: 'keyTEX',
        path: 'keyTEX',
        component: () => import('../views/keyTEX'),
        meta: {
          requiresAuth: true,
          // either permission for whole keytex or for individual skype keytex-key
          requiredPermissions: requiredPermissions.keyTEXAny,
          hardReload: true,
        },
      },
      {
        name: 'Release Notes',
        path: 'release-notes',
        component: () => import('../views/ReleaseNotes.vue'),
        meta: {
          requiresAuth: true,
          requiredPermissions: requiredPermissions.keyTEXAll,
          hardReload: true,
        },
      },
    ],
  },
  {
    name: 'NotFound',
    path: '*',
    component: () => import('../pages/NotFound'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['sysAuth/isLoggedIn']) {
      const permissionNeeded = to.matched.some((record) => record.meta.requiredPermissions);
      if (permissionNeeded && to.meta.requiredPermissions.length !== 0) {
        const currentPermissions = store.getters['sysAuth/getUserPermissions'];
        if (
          requiredPermissions.checkRequiredPermissions(
            to.meta.requiredPermissions,
            currentPermissions,
          )
        ) {
          next();
        } else {
          next('/pages/NotFound');
          return;
        }
      }
      next();
      return;
    }
    next('/pages/login2FA');
    return;
  }
  next();
});

router.afterEach((to, from) => {
  if (to.meta.hardReload && from.path != '/') {
    router.go(to.path);
  }
});

export default router;
