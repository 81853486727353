const { colors } = require('vuetify/lib');

const colorBase = require('./colorBase');

const ratsColorTheme = {
  // light: {
  //   primary: colorBase.wildBlueYonder,
  //   primaryAccent: colorBase.periwinkleCryola,
  //   secondary: colorBase.silverChalice,
  //   secondaryAccent: colorBase.timberwolf,
  //   background: colorBase.white,
  //   success: colors.green.base,
  //   error: colors.red.base,
  //   warning: colors.orange.base,
  // },
  // dark: {
  //   primary: colorBase.oxfordblue,
  //   primaryAccent: colorBase.darkCornflowerBlue,
  //   secondary: colorBase.middleGrey,
  //   secondaryAccent: colorBase.silverChalice,
  //   background: colorBase.black,
  //   success: colorBase.lincolnGreen,
  //   error: colors.red.base,
  //   // error: colorBase.rosewood,
  //   warning: colorBase.goldenBrown,
  //   info: colorBase.cadetBlueCrayola,
  // },
  light: {
    primary: colors.teal.lighten1,
    primaryAccent: colors.teal.lighten4,
    secondary: colors.blueGrey.lighten2,
    secondaryAccent: colors.blueGrey.lighten4,
    background: colors.blueGrey.lighten5,
    success: colors.green.base,
    error: colors.red.base,
    warning: colorBase.goldenBrown,
    info: colorBase.cadetBlueCrayola,
  },
  dark: {
    primary: colors.teal.darken4,
    primaryAccent: colors.teal.darken2,
    secondary: colors.blueGrey.darken1,
    secondaryAccent: colors.blueGrey.darken2,
    background: colors.blueGrey.darken4,
    success: colorBase.lincolnGreen,
    error: colors.red.base,
    warning: colorBase.goldenBrown,
    info: colorBase.cadetBlueCrayola,
  },
};

export default ratsColorTheme;
