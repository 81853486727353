module.exports = {
  writeToStorage(key, value) {
    const valueBuffer = Buffer.from(value);
    const keyBuffer = Buffer.from(key);
    localStorage.setItem(keyBuffer.toString('base64'), valueBuffer.toString('base64'));
    // localStorage.setItem(key, value);
  },
  readFromStorage(key) {
    const keyBuffer = Buffer.from(key);
    const encodedValue = localStorage.getItem(keyBuffer.toString('base64'));
    if (encodedValue) {
      const decodedValue = Buffer.from(encodedValue, 'base64');
      return decodedValue.toString('ascii');
    }
    return null;
    // return localStorage.getItem(key);
  },
  removeFromStorage(key) {
    const keyBuffer = Buffer.from(key);
    localStorage.removeItem(keyBuffer.toString('base64'));
  },
};
