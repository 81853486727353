<template>
  <transition name="slide-fade" mode="out-in">
    <div ref="nodeDetails" :key="showAlert">
      <v-alert v-model="showAlertComp" :color="alertColor" class="mt-5">
        {{ alertText }}
      </v-alert>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MexAlert',
  description: 'MedITEX Alert Component',
  props: {
    showAlert: {
      type: Boolean,
      default: false,
      description: 'If true alert is displayed',
    },
    alertTimeout: {
      type: Number,
      default: 2000,
      description: 'Milliseconds for alert to be displayed',
    },
    alertColor: {
      type: String,
      default: 'error',
      description: 'Color of the alert',
    },
    alertText: {
      type: String,
      default: 'This is an alert',
      description: 'Content of the alert',
    },
  },
  computed: {
    showAlertComp() {
      if (this.showAlertComp) {
        if (this.alertTimeout !== null) {
          // eslint-disable-next-line vue/no-async-in-computed-properties
          setTimeout(() => false, this.alertTimeout);
        }
        return false;
      }
      return true;
    },
  },
};
</script>

<style>
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for <2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
