const localStorageManager = require('../functions/localStorageManager');

/**
 * Array of objects of properties which should be observed.
 * - name: name of the property
 * - abbreviation: how it's written in the store
 * - default: what the default-value should be
 * - (optional): setFunction --> gets set as setter-function, if not defined: default-setter-function
 * - (optional): getFunction --> gets set as getter-function, if not defined: default-getter-function
 */
const properties = [
  // views
  { name: 'LocationView', abbreviation: 'slv', default: null, setFunction: null, getFunction: null },
  { name: 'ClinicView', abbreviation: 'scv', default: null, setFunction: null, getFunction: null },
  { name: 'OrganizationView', abbreviation: 'sov', default: null, setFunction: null, getFunction: null },
  // endpoints-statistics
  { name: 'ClinicEndpointsStatisticsClinic', abbreviation: 'cesc', default: null, setFunction: null, getFunction: null },
  { name: 'ClinicEndpointsStatisticsOrganization', abbreviation: 'ceso', default: null, setFunction: null, getFunction: null },
  // cycletypes statistics
  { name: 'ClinicCycleTypesStatisticsClinic', abbreviation: 'cctsc', default: null, setFunction: null, getFunction: null },
  { name: 'ClinicCycleTypesStatisticsOrganization', abbreviation: 'cctso', default: null, setFunction: null, getFunction: null },
  { name: 'ClinicCycleTypesStatisticsStartDate', abbreviation: 'cctssd', default: null, setFunction: null, getFunction: null },
  { name: 'ClinicCycleTypesStatisticsEndDate', abbreviation: 'cctsed', default: null, setFunction: null, getFunction: null },
  // rental license view
  { name: 'RentalLicenseViewClinic', abbreviation: 'rlvc', default: null, setFunction: null, getFunction: null },
  { name: 'RentalLicenseViewOrganization', abbreviation: 'rlvo', default: null, setFunction: null, getFunction: null },
  // feature license view
  { name: 'FeatureLicenseViewLocation', abbreviation: 'flvl', default: null, setFunction: null, getFunction: null },
  { name: 'FeatureLicenseViewClinic', abbreviation: 'flvc', default: null, setFunction: null, getFunction: null },
  { name: 'FeatureLicenseViewOrganization', abbreviation: 'flvo', default: null, setFunction: null, getFunction: null },
  // feature license settings
  { name: 'FeatureLicenseSettingsLocation', abbreviation: 'flsl', default: null, setFunction: null, getFunction: null },
  { name: 'FeatureLicenseSettingsClinic', abbreviation: 'flsc', default: null, setFunction: null, getFunction: null },
  { name: 'FeatureLicenseSettingsOrganization', abbreviation: 'flso', default: null, setFunction: null, getFunction: null },
  // clinic-endpoints view
  { name: 'ClinicEndpointsViewClinic', abbreviation: 'cevc', default: null, setFunction: null, getFunction: null },
  { name: 'ClinicEndpointsViewOrganization', abbreviation: 'cevo', default: null, setFunction: null, getFunction: null },
  { name: 'ClinicEndpointsViewStartDate', abbreviation: 'cevsd', default: null, setFunction: null, getFunction: null },
  { name: 'ClinicEndpointsViewEndDate', abbreviation: 'ceved', default: null, setFunction: null, getFunction: null },
  // Clinic View Manually Managed
  { name: 'ClinicViewManuallyManaged', abbreviation: 'cvmm', default: null, setFunction: null, getFunction: null },
  // SQL Statement Editor
  { name: 'SqlStatementEditorSelectedStatement', abbreviation: 'ssess', default: null, setFunction: null, getFunction: null },
  // Location Rental License Settings
  { name: 'RentalLicenseSettingsLocation', abbreviation: 'rlsl', default: null, setFunction: null, getFunction: null },
  { name: 'RentalLicenseSettingsClinic', abbreviation: 'rlsc', default: null, setFunction: null, getFunction: null },
  { name: 'RentalLicenseSettingsOrganization', abbreviation: 'rlso', default: null, setFunction: null, getFunction: null },
  // ProductInfo
  { name: 'ProductInfo', abbreviation: 'pi', default: null, setFunction: null, getFunction: null },
  // Cycle Group Clinics Overview
  { name: 'CycleGroupClinicOrganization', abbreviation: 'cgco', default: null, setFunction: null, getFunction: null },
  { name: 'CycleGroupClinicYear', abbreviation: 'cgcy', default: null, setFunction: null, getFunction: null },
  { name: 'CycleGroupClinicCycleGroupTemplate', abbreviation: 'cgct', default: null, setFunction: null, getFunction: null },
];

let state = {};
let mutations = {};
let getters = {};

properties.forEach((property) => {
  if (!property.abbreviation || !property.name) {
    throw new Error('Invalid property to write in store: "abbreviation" or "name" missing');
  }
  state[property.abbreviation] = localStorageManager.readFromStorage(property.abbreviation) || property.default;
  // if specific setFunction is given: assign this function to the mutations-property called set + name starting with uppercase, otherwise: use standard-function
  mutations['set' + property.name.charAt(0).toUpperCase() + property.name.slice(1)] = property.setFunction
    ? property.setFunction
    : (state, value) => {
      state[property.abbreviation] = JSON.stringify(value);
      localStorageManager.writeToStorage(property.abbreviation, state[property.abbreviation]);
    };
  // if specific getFunction is given: assign this function to the getters-property called name starting with lowercase, otherwise: use standard-function
  getters[property.name.charAt(0).toLowerCase() + property.name.slice(1)] = (property.getFunction) || (state => state[property.abbreviation]);
});

// add logout-option which sets every property listed in the array to null
mutations['logout'] = (state) => {
  properties.forEach(property => localStorageManager.removeFromStorage(property.abbreviation));
};

export const selectedProperties = {
  namespaced: true,
  state: state,
  mutations: mutations,
  getters: getters,
};
