<template>
  <!-- [DaWo] !!!! IF YOU CHANGE SOMETHING HERE ALSO CHANGE IT FOR TOOLTIP AND TOOLTIP-BADGE MEX BUTTON !!!! -->
  <v-btn
    v-bind="$attrs"
    :icon="iconOnly"
    :color="color"
    @click="$emit('click')"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    class="ml-1 mr-1"
  >
    <v-icon v-if="icon" :class="iconOnly || content === '' ? '' : 'mr-2'"> {{ icon }}</v-icon>
    {{ iconOnly ? '' : content }}
    <template v-if="loadingIcon !== ''" v-slot:loader>
      <span class="custom-loader">
        <v-icon>{{ loadingIcon }}</v-icon>
      </span>
    </template>
  </v-btn>
</template>

<script>
export default {
  name: 'MexBasicBtn',
  props: {
    content: {
      type: String,
      default: 'Click Me',
      description: 'Content of the button',
    },
    icon: {
      type: String,
      default: '',
      description: 'Icon of the button',
    },
    iconOnly: {
      type: Boolean,
      default: false,
      description: 'Defines if only the icon or the content and the icon is shown',
    },
    loadingIcon: {
      type: String,
      default: 'mdi-loading',
      description: 'Defines the icon spinning on loading',
    },
    color: {
      type: String,
      default: 'white',
      description: 'Defines the color of the button',
    },
    attrs: {
      description: 'Attrs for tooltip',
    },
    on: {
      description: 'On for tooltip',
    },
  },
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
