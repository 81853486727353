<template>
  <v-sheet v-bind="$attrs" :elevation="elevation" :color="color" :class="computedClass" :rounded="rounded" :shaped="shaped">
    <slot />
  </v-sheet>
</template>

<script>
export default {
  name: 'MexSheet',
  description: 'MedITEX Sheet',
  props: {
    elevation: {
      type: String,
      default: '13',
      description: 'Defines the elevation of the sheet',
    },
    color: {
      type: String,
      default: 'primary',
      description: 'Defines the color of the sheet',
    },
    shaped: {
      type: Boolean,
      default: true,
      description: 'Defines if the sheet is shaped',
    },
    rounded: {
      type: Boolean,
      default: false,
      description: 'Defines if the sheet is rounded',
    },
    sheetPadding: {
      type: Array,
      default: () => [5],
      description: 'Padding of the sheet | Default: 5',
    },
    sheetWidth: {
      type: String,
      default: '',
    },
  },
  computed: {
    computedClass() {
      const paddingSides = ['l', 't', 'r', 'b'];
      if (this.sheetPadding.length === 4) {
        // margin for all four sides is provided
        let classString = '';
        for (let i = 0; i < paddingSides.length; i++) {
          classString += `p${paddingSides[i]}-${this.sheetPadding[i]} `;
        }
        return classString;
      }
      return `pa-${this.sheetPadding[0]}`;
    },
  },
};
</script>

<style></style>
