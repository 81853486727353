<template>
  <v-app-bar app clipped-left :color="appBarColor" dense>
    <slot name="drawerToggleSlot"></slot>
    <v-divider vertical inset class="mx-4" />
    <v-avatar v-if="appBarAvatar !== ''" class="mr-3">
      <!-- for now the path to this image must be set hard here. Because vue has problems passing a path to a child component -->
      <img :src="require(`../../../assets/${appBarAvatar}`)" :scale="appBarIconScale" alt="appBarAvatar" />
    </v-avatar>
    <v-toolbar-title>{{ appBarTitle }}</v-toolbar-title>
    <slot></slot>
    <v-spacer v-if="appBarSpacer"></v-spacer>
    <v-menu bottom left offset-y origin="top right" :close-on-content-click="false" transition="scale-transition">
      <template v-slot:activator="{ attrs, on }">
        <!-- [DaWo] 11-11-2020: We cant use the mex-btn here. Cant pass the activator props... for now -->
        <!-- <mex-btn class="ml-2" min-width="0" :icon="appBarMenuIcon" :iconOnly="true" v-bind="attrs" v-on="on" /> -->
        <mex-badge :value="getNotifications" :content="getNotifications" left color="error">
          <v-btn v-bind="attrs" icon v-on="on">
            <v-icon>{{ appBarMenuIcon }}</v-icon>
          </v-btn>
        </mex-badge>
      </template>
      <v-list :tile="false" flat nav>
        <template v-for="(menuItem, i) in appBarMenuItems">
          <v-divider v-if="menuItem.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
          <v-app-bar-item v-else-if="menuItem.button" :key="`item-${i}`">
            <v-list-item>
              <v-btn elevation="0" text block @click="$emit(menuItem.clickEvent, $event)">
                <v-icon class="mr-2">{{ menuItem.icon }}</v-icon>
                {{ menuItem.title }}
              </v-btn>
            </v-list-item>
          </v-app-bar-item>
          <app-bar-item v-else :key="`item-${i}`" :to="menuItem.to">
            <v-list-item-title v-text="menuItem.title" />
          </app-bar-item>
        </template>
      </v-list>
    </v-menu>
    <mex-local-select class="mt-3" v-if="localSelectEnabled" />
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex';
// Components
import { VHover, VListItem } from 'vuetify/lib';

export default {
  name: 'MexAppBar',
  description: 'MedITEX Application Bar',
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({ hover }) => h(
              VListItem,
              {
                attrs: this.$attrs,
                class: {
                  'black--text': !hover,
                  'white--text secondary elevation-12': hover,
                },
                props: {
                  activeClass: '',
                  dark: hover,
                  link: true,
                  ...this.$attrs,
                },
              },
              this.$slots.default,
            ),
          },
        });
      },
    },
  },
  props: {
    // open / close navbar
    drawerToggle: {
      type: Boolean,
      default: false,
      description: 'Open / close the drawer',
    },
    // color of the app bar
    appBarColor: {
      type: String,
      default: 'primary',
      description: 'AppBar color',
    },
    appBarAvatar: {
      type: String,
      default: '',
      description: 'Image path depending on ../assets',
    },
    // scales the image if there is a image path
    // for the app bar avatar
    appBarIconScale: {
      type: Number,
      default: 0.25,
      description: 'Scales the size of the appBar-icon',
    },
    // app bar title
    appBarTitle: {
      type: String,
      default: 'App Bar Title',
      description: 'AppBar Title',
    },
    // enable / disable the menu on the right of the
    // app bar
    appBarSpacer: {
      type: Boolean,
      default: false,
      description: 'Defines if appBar-elements is spaced',
    },
    appBarMenuEnabled: {
      type: Boolean,
      default: false,
      description: 'Defines if appBar-menu is shown or not',
    },
    // sets Icon for menu if app bar menu is enabled
    appBarMenuIcon: {
      type: String,
      default: 'mdi-dots-horizontal-circle-outline',
      description: 'Defines the icon for the appBar-menu',
    },
    // object containing list items for app bar menu
    appBarMenuItems: {
      type: Array,
      default: () => [
        // Simple menu item with no functionality
        {
          title: 'Menu Item 1',
        },
        // Divider for visual separation
        {
          divider: true,
        },
        // menu item with route functionality
        {
          title: 'Menu Item 2',
          to: '/some/path',
        },
        // menu item button -> @click fires event
        // which can be catch in parent
        {
          button: true,
          icon: 'mdi-lightbulb-on',
          title: 'Menu Item 3',
          clickEvent: 'eventToBeFired',
        },
      ],
      description: 'Array of items for the appBar-Menu',
    },
    localSelectEnabled: {
      type: Boolean,
      default: false,
      description: 'Defines if localSelect is shown or not',
    },
  },
  computed: {
    ...mapGetters('badges', ['getNotificationBadge']),
    ...mapGetters('sysAuth', ['getUser']),
    setItemText(data) {
      return data;
    },
    getNotifications() {
      return this.getNotificationBadge;
    },
  },
  methods: {
    toggleDrawer() {
      this.drawerToggle = !this.drawerToggle;
    },
  },
};
</script>

<style></style>
