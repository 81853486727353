const system = [
  ["read_System"],
  ["read_System_User Role Administration"],
  ["read_System_User Role Administration_User"],
  ["read_System_User Role Administration_Role"],
  ["read_System_System Settings"]
];
const userRoleAdministration = [
  ["read_System_User Role Administration"],
  ["read_System_User Role Administration_User"],
  ["read_System_User Role Administration_Role"]
];
const registerUser = [["write_System_User Role Administration_User"]];
const userView = [["read_System_User Role Administration_User"]];
const userOverview = [["read_System_User Role Administration_User"]];
const addRole = [["write_System_User Role Administration_Role"]];
const roleView = [["read_System_User Role Administration_Role"]];
const roleOverview = [["read_System_User Role Administration_Role"]];
const systemSettings = [["read_System_System Settings"]];
const customerAdministration = [
  ["read_Customer Administration"],
  ["read_Customer Administration_Clinic Administration"],
  ["read_Customer Administration_Organization Administration"],
  ["read_Customer Administration_Location Administration"]
];
const clinicAdministration = [["read_Customer Administration_Clinic Administration"]];
const manuallyManagedClinics = [["write_Customer Administration_Clinic Administration"]];
const organizationAdministration = [["read_Customer Administration_Organization Administration"]];
const locationAdministration = [["read_Customer Administration_Location Administration"]];
const licenseAdministration = [
  ["read_License Administration"],
  ["read_License Administration_Rental License"],
  ["read_License Administration_Feature License"]
];
const rentalLicense = [["read_License Administration_Rental License"]];
const featureLicense = [["read_License Administration_Feature License"]];
const licenseSettings = [["_License Administration_License Settings"]];
const statistics = [["_Statistics"], ["_Statistics_Cycle Types"], ["_Statistics_Endpoints"]];
const cycleTypesStatistics = [["_Statistics_Cycle Types"]];
const endpointsStatistics = [["_Statistics_Endpoints"]];
const sqlBroadcaster = [["read_SQL Broadcaster"]];
const keyTEXAny = [
  ['_keyTEX_Survey_Live'], ['_keyTEX_Survey_Demo'], ['_keyTEX_Mail_Relay'], ['_keyTEX_License_Server_System'],
  ['_keyTEX_License_Server_REST_API'], ['_keyTEX_Skype'], ['_keyTEX_KeyPass'], ['_keyTEX_Critex_Key'],
  ['_keyTEX_Discord'], ['_keyTEX_Artbox_Manager_System']
];
const keyTEXAll = [[...keyTEXAny.flat()]];

function updatePermissions(oldPermissions, newPermissions, store) {
  if (!(JSON.stringify(oldPermissions) === JSON.stringify(newPermissions))) {
    store.commit("sysAuth/updateUserPermissions", newPermissions);
  }
}

function checkRequiredPermissions(reqPermissions, permissions) {
  for (let i = 0; i < reqPermissions.length; i++) {
    let check = true;
    for (let j = 0; j < reqPermissions[i].length; j++) {
      if (!permissions.includes(reqPermissions[i][j])) {
        check = false;
      }
    }
    if (check) return true;
  }
  return false;
}

export default {
  updatePermissions,
  checkRequiredPermissions,
  system,
  userRoleAdministration,
  registerUser,
  userView,
  userOverview,
  addRole,
  roleView,
  roleOverview,
  systemSettings,
  customerAdministration,
  clinicAdministration,
  manuallyManagedClinics,
  organizationAdministration,
  locationAdministration,
  licenseAdministration,
  rentalLicense,
  featureLicense,
  licenseSettings,
  statistics,
  cycleTypesStatistics,
  endpointsStatistics,
  sqlBroadcaster,
  keyTEXAny,
  keyTEXAll
};
