module.exports = {
  black: '#000000',
  darkerBlueGrey: '#1a2226',
  middleGrey: '#857F74',
  silverChalice: '#B3AFA8',
  white: '#FFFFFF',
  // ----
  oxfordblue: '#14213D',
  darkCornflowerBlue: '#28427B',
  aliceBlue: '#F0F3FA',
  periwinkleCryola: '#C1CFEB',
  // ----
  darkOlive: '#606C38',
  moseGreen: '#8FA253',
  lincolnGreen: '#10540B',
  // ----
  rosewood: '#540B0E',
  // ----
  goldenBrown: '#AB6509',
  // ----
  wildBlueYonder: '#93AADC',
  platinum: '#e5e5e5',
  paleSpringBud: '#CCD5AE',
  grey: '#848586',
  mintCream: '#EDF4ED',
  darkLiver: '#54494b',
  grayWeb: '#7e8287',
  morningBlue: '#9da39a',
  oldRose: '#b98389',
  prussianBlue: '#1c3144',
  rossoCorsa: '#d00000',
  selectiveYellow: '#ffba08',
  cadetBlueCrayola: '#a2aebb',
  cryola: '#F5CB5C',
  mediumChampange: '#EFDC9E',
  alabaster: '#E8EDDF',
  alabaster2: '#DCE4DA',
  timberwolf: '#CFDBD5',
  jet: '#333533',
};
