<template>
  <!-- This is a wrapper for the BaseMaterialCard -->
  <!-- Its passes by all basic slots and props of the BaseMaterialCard -->
  <!-- while adding a extra div for MexCard specific margin -->
  <div :class="getMexCardMargin">
    <base-material-card
      :class="cardClass"
      v-bind="$attrs"
      :avatar="avatar"
      :color="color"
      :hoverReveal="hoverReveal"
      :icon="icon"
      :image="image"
      :inline="inline"
      :title="title"
      :text="text"
    >
      <!-- This empty slot is needed for all the card content -->
      <slot />

      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
      <template v-for="(_, slot) in $slots" v-slot:[slot]>
        <slot :name="slot" />
      </template>
    </base-material-card>
  </div>
</template>
<script>
export default {
  name: 'MexCard',
  description: 'MedITEX Card',
  components: {
    BaseMaterialCard: () => import('../CtComponents/MaterialCard.vue'),
  },
  props: {
    cardClass: {
      type: String,
      default: 'neum',
    },
    avatar: {
      type: String,
      default: '',
      description: 'Avatar of the card',
    },
    color: {
      type: String,
      default: 'primary',
      description: 'Defines the color of the card-sheet',
    },
    hoverReveal: {
      type: Boolean,
      default: false,
      description: 'Defines if the card-sheet reacts to mouseover',
    },
    icon: {
      type: String,
      default: undefined,
      description: 'Icon of the card',
    },
    image: {
      type: Boolean,
      default: false,
      description: 'Image of the card',
    },
    inline: {
      type: Boolean,
      default: false,
      description: 'Defines if image/avatar/icon and heading will be displayed in one line',
    },
    text: {
      type: String,
      default: '',
      description: 'Text of the card',
    },
    title: {
      type: String,
      default: '',
      description: 'Title of the card',
    },
    mexCardMargin: {
      type: Array,
      default: () =>
        // Used if no four margin values as provided
        // -> all around margin 2 and top margin 13
        [2, 13],
      description: 'Defines the margin of the card | left, top, right, bottom | default: l=2, t=13',
    },
  },
  computed: {
    getMexCardMargin() {
      const marginSides = ['l', 't', 'r', 'b'];
      if (this.mexCardMargin.length === 4) {
        // margin for all four sides is provided
        let classString = '';
        for (let i = 0; i < marginSides.length; i++) {
          classString += `m${marginSides[i]}-${this.mexCardMargin[i]} `;
        }
        return classString;
      }
      return `ma-${this.mexCardMargin[0]} mt-${this.mexCardMargin[1]}`;
    },
  },
};
</script>

<style>
</style>
