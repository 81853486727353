import SystemUsersService from "../services/systemUsers.service";
import requiredPermissions from "../requiredPermissions";

function fetchCurrentUserPermissions(requiredPermissionsForView, store) {
  return new Promise(function (resolve, reject) {
    SystemUsersService.getCurrentUserPermissions().then(response => {
      requiredPermissions.updatePermissions(store.getters['sysAuth/getUserPermissions'], response.data.permissions, store);
      if (requiredPermissionsForView.length === 0) {
        resolve(true);
      } else {
        resolve(requiredPermissions.checkRequiredPermissions(requiredPermissionsForView, response.data.permissions));
      }
    })
      .catch((err) => {
        reject(err);
      })
  });
}

const userPermissions = {
  fetchCurrentUserPermissions,
};
export default userPermissions;
