import Vue from 'vue';
import Vuex from 'vuex';

import { badges } from './badgesStore';
import { locales } from './Vue_Frontend_Template_Vuex/localesStore';
import { theme } from './Vue_Frontend_Template_Vuex/themeStore';
import { sysAuth } from './systemUserAuth.store';
import { selectedProperties } from "./selectedProperties.store";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    sysAuth,
    locales,
    badges,
    theme,
    selectedProperties,
  },
});
