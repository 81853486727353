import Vue from 'vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { email, max, min, numeric, required } from 'vee-validate/dist/rules';

extend('email', email);
extend('max', max);
extend('min', min);
extend('numeric', numeric);
extend('required', required);
extend('regexPemFile', {
  validate: (value) => {
    const pattern = /^.*\.(pem)$/;
    return pattern.test(value.name) || 'unaccepted file type';
  },
});
extend('passwordConfirm', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match',
});
extend('password', {
  validate: (value) => {
    const pattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[!"#$%&\'()*+,-.:;<=>?@a^_`{|}~])(?=.*[0-9])(?=.{8,})');
    return (
      (pattern.test(value) && value.length >= 8) ||
      'Must at least contain an uppercase letter, a lowercase letter, a number, and a special character (! " # $ % & \' ( ) * + , - . : ; < = > ? @ a ^ _ ` { | } ~) with a min length of 8 characters.'
    );
  },
});

Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
