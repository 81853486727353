<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        :label="datePickerLabel"
        :prepend-icon="datePickerIcon"
        readonly
        v-bind="attrs"
        v-on="on"
        @change="$refs.menu.save(date)"
      ></v-text-field>
    </template>
    <v-date-picker v-model="date" no-title scrollable>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: 'MexDatePicker',
  description: 'MedITEX Date Picker',
  props: {
    datePickerLabel: {
      type: String,
      default: 'Pick a date',
    },
    datePickerIcon: {
      type: String,
      default: 'mdi-calendar',
    },
    date: {
      type: Date,
      default: () => new Date().toISOString().substr(0, 10),
    },
  },
  data() {
    return {
      menu: false,
    };
  },
};
</script>

<style></style>
