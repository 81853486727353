<template>
    <div>
        <mex-select v-bind="$attrs" :items="localItems" small :autoScaleWidth="true" @change="selectionChanged"
                    :selection="selectedIdx" dense>
            <template v-slot:selection="data">
                <country-flag :country="data.item.iso" size="small"/>
            </template>
            <template v-slot:item="data" class="v-list-item__tile">
                <country-flag class="mr-2" :country="data.item.iso" size="normal"/>
                {{ data.item.text }}
            </template>
        </mex-select>
    </div>
</template>

<script>
import CountryFlag from 'vue-country-flag';
import locales from '../../../locales';

export default {
  name: 'MexLocalSelect',
  components: {
    CountryFlag,
  },
  computed: {},
  data() {
    return {
      selectedIdx: -1,
      localItems: [],
    };
  },
  created() {
    this.$i18n.locale = this.$store.getters['locales/selectedLocal'].selectedLocal;
    this.selectedIdx = Object.keys(locales).indexOf(this.$i18n.locale);
  },
  methods: {
    selectionChanged(value) {
      if (this.selectedIdx > -1) {
        this.selectedIdx = value;
        this.$store.commit('locales/setLocal', locales[Object.keys(locales)[this.selectedIdx]]);
        this.$i18n.locale = this.$store.getters['locales/selectedLocal'].selectedLocal;
      }
    },
    getAppBarLocalesItems() {
      const localItems = [];
      let idx = 0;
      Object.keys(locales).forEach((local) => {
        localItems.push({
          value: idx,
          iso: locales[local].iso,
          json: locales[local].json,
          text: locales[local].description,
        });
        if (locales[local].iso === this.$store.getters['locales/selectedLocal'].selectedLocal) {
          this.selectedIdx = idx;
        }
        idx += 1;
      });
      return localItems;
    },
  },
  mounted() {
    this.localItems = this.getAppBarLocalesItems();
  },
};
</script>

<style></style>
