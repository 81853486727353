<template>
  <v-footer v-bind="$attrs" app class="pl-2 pr-2 pb-0 pt-0" style="z-index:5;">
    <v-row justify="center">
      <v-col cols="1">
        <mex-btn iconOnly icon="mdi-arrow-left-circle" large color="primaryAccent" @click="$router.go(-1)" />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="auto" v-for="(route, n) in quickRoutes" :key="`quick-route-${n}`" align-self="center">
        <v-menu v-if="route.children" top offset-y :close-on-content-click="true" open-on-hover>
          <template v-slot:activator="{ attrs, on }">
            <!-- [DaWo] 11-11-2020: We cant use the mex-btn here. Cant pass the activator props... for now -->
            <!-- <mex-btn class="ml-2" min-width="0" :icon="appBarMenuIcon" :iconOnly="true" v-bind="attrs" v-on="on" /> -->
            <v-btn class="ml-2" min-width="0" icon v-bind="attrs" v-on="on">
              <v-icon color="primaryAccent">{{ route.icon }}</v-icon>
            </v-btn>
          </template>
          <v-list :tile="false" nav>
            <v-list-item>
              <mex-p :content="route.title" fontColor="primaryAccent" class="mb-0" fontSize="overline" />
            </v-list-item>
            <template v-for="(menuItem, i) in route.children">
              <v-divider v-if="menuItem.divider" :key="`divider-${i}`" class="mb-2 mt-2" />
              <v-list-item v-else :key="`item-${i}`" :to="menuItem.to" class="justify-center">
                <v-icon color="primaryAccent">{{ menuItem.icon }}</v-icon>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <mex-btn
          v-else
          :icon="route.icon"
          iconOnly
          @click="$router.push(route.to)"
          color="primaryAccent"
          :tooltip="route.title"
          smallTooltip
          tooltipPosition="top"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1" align-self="center">
        <v-flex class="d-flex justify-end">
          <mex-p :content="getCurrentVersion" fontSize="caption" class="mb-0" fontColor="primaryAccent" />
        </v-flex>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  name: 'MexLinkFooter',
  description: 'MedITEX Link Footer Component',
  props: {
    quickRoutes: {
      type: Array,
      default: () => [],
      description: 'Quickroutes for the link area of the footer. Quickroute is the same as MexNavBar item',
    },
  },
  computed: {
    getCurrentVersion() {
      return `v${process.env.VUE_APP_FRONTEND_VERSION}`;
    },
  },
  methods: {
    goToQuickRoute(route) {
      this.$router.push(route.to);
    },
  },
};
</script>

<style>
</style>
