<template>
  <validation-observer v-slot="{ handleSubmit }">
    <form @submit.prevent="handleSubmit(validateLoginForm)">
      <mex-card icon="mdi-fingerprint" :title="title" color="primary" light max-width="100%" width="400">
        <validation-provider v-slot="{ errors }" rules="required" name="Username">
          <v-text-field color="secondary" label="Username" prepend-icon="mdi-account" class="mt-10" :error-messages="errors" v-model="i_username" />
        </validation-provider>
        <validation-provider v-slot="{ errors }" rules="required" name="password">
          <v-text-field
            color="secondary"
            label="Password"
            type="password"
            prepend-icon="mdi-form-textbox-password"
            class="mt-10"
            :error-messages="errors"
            v-model="i_password"
          />
        </validation-provider>
        <v-btn type="submit">
          Login
        </v-btn>
      </mex-card>
    </form>
  </validation-observer>
</template>

<script>
export default {
  name: 'MexLogin',
};
</script>

<style></style>
