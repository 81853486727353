<template>
  <mex-card title="COLOR THEME PICKER" icon="mdi-palette">
    <mex-select customCarret="mdi-palette" :items="themeSelection" v-model="selectedIdx">
      <template v-slot:selection="data">{{ data.item.name }} </template>
      <template v-slot:item="data">
        <v-row full-width>
          <v-col cols="15" align-self="start">
            <mex-p alignment="center" :content="data.item.name" fontSize="h6" />
          </v-col>
          <v-col>
            <mex-p content="light" alignment="center" fontSize="overline" />
            <mex-sheet
              v-for="color in getKeyAndColor(data.item.light)"
              :key="color + Math.random()"
              :color="getColorHex(data.item.light, color)"
              :shaped="false"
              :rounded="false"
            >
              <mex-p :content="color" fontColor="black" />
            </mex-sheet>
          </v-col>
          <v-col>
            <mex-p content="dark" alignment="center" fontSize="overline" />
            <mex-sheet
              v-for="color in getKeyAndColor(data.item.dark)"
              :key="color + Math.random()"
              :color="getColorHex(data.item.dark, color)"
              :shaped="false"
              :rounded="false"
            >
              <mex-p :content="color" fontColor="white" />
            </mex-sheet>
          </v-col>
        </v-row>
      </template>
    </mex-select>
    <v-row v-if="selectedIdx !== -1">
      <v-col>
        <mex-p content="light" alignment="center" fontSize="overline" />
        <mex-sheet
          v-for="color in getKeyAndColor(selectedIdx.light)"
          :key="color + Math.random()"
          :color="getColorHex(selectedIdx.light, color)"
          :shaped="false"
          :rounded="false"
        >
          <mex-p :content="color" fontColor="black" />
        </mex-sheet>
      </v-col>
      <v-col>
        <mex-p content="dark" alignment="center" fontSize="overline" />
        <mex-sheet
          v-for="color in getKeyAndColor(selectedIdx.dark)"
          :key="color + Math.random()"
          :color="getColorHex(selectedIdx.dark, color)"
          :shaped="false"
          :rounded="false"
        >
          <mex-p :content="color" fontColor="white" />
        </mex-sheet>
      </v-col>
    </v-row>
    <mex-btn v-if="selectedIdx !== -1" content="Set Theme!" icon="mdi-download" @click="setColorTheme" />
  </mex-card>
</template>

<script>
import themes from '../../../../configs/MedITEX-Connect-Assets/color.configs/index';

export default {
  name: 'MexThemeChanger',
  data() {
    return {
      themeSelection: [],
      selectedIdx: -1,
    };
  },
  methods: {
    getColorHex(item, colorKey) {
      return item[colorKey];
    },
    getKeyAndColor(colorSet) {
      return Object.keys(colorSet);
    },
    setColorTheme() {
      this.$store.commit('theme/setActTheme', this.selectedIdx);
      this.$router.go();
    },
  },
  created() {
    Object.keys(themes).forEach((theme) => {
      this.themeSelection.push({
        name: themes[theme].name,
        light: themes[theme].light,
        dark: themes[theme].dark,
      });
    });
  },
};
</script>

<style></style>
