import '@fortawesome/fontawesome-free/css/all.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import basicColorTheme from '../../configs/MedITEX-Connect-Assets/color.configs/basic';

const selectedColorTheme = require('../../configs/MedITEX-Connect-Assets/color.configs/meditexLicenseServer').default;

Vue.use(Vuetify);

function getLightColorSet() {
  Object.keys(basicColorTheme.light).forEach((prop) => {
    if (selectedColorTheme.light[prop] !== undefined) {
      basicColorTheme.light[prop] = selectedColorTheme.light[prop];
    }
  });
  return basicColorTheme.light;
}
function getDarkColorSet() {
  Object.keys(basicColorTheme.dark).forEach((prop) => {
    if (selectedColorTheme.dark[prop] !== undefined) {
      basicColorTheme.dark[prop] = selectedColorTheme.dark[prop];
    }
  });
  return basicColorTheme.dark;
}
function getColorSets() {
  const retObj = {
    light: getLightColorSet(),
    dark: getDarkColorSet(),
  };
  return retObj;
}
// Ensure you are using css-loader
const vuetify = new Vuetify({
  theme: {
    themes: getColorSets(),
    options: { customProperties: true },
    icons: {
      iconFont: 'fa',
    },
  },
});

export default vuetify;
