<template>
  <v-badge
    v-bind="$attrs"
    :content="content"
    :value="value"
    :inline="inline"
    :tile="tile"
    :bordered="bordered"
    :dot="dot"
    :bottom="bottom"
    :left="left"
    :overlap="overlap"
  >
    <slot />
  </v-badge>
</template>

<script>
export default {
  name: 'MexBadge',
  description: 'MedITEX Badge',
  props: {
    content: {
      default: '',
      description: 'Content of the badge',
    },
    value: {
      type: Number,
      default: 0,
      description: 'Value of the badge',
    },
    inline: {
      type: Boolean,
      default: true,
      description: 'Defines if the badge is in one line with the main element',
    },
    tile: {
      type: Boolean,
      default: false,
      description: 'Defines the appearance of the badge',
    },
    bordered: {
      type: Boolean,
      default: false,
      description: 'Defines if the badged has a border',
    },
    dot: {
      type: Boolean,
      default: false,
      description: 'Defines if the badge is displayed only as a dot',
    },
    bottom: {
      type: Boolean,
      default: false,
      description: 'Defines if the badge is displayed at the bottom of the main element',
    },
    left: {
      type: Boolean,
      default: false,
      description: 'Defines if the badge is displayed at the left of the main element',
    },
    overlap: {
      type: Boolean,
      default: true,
      description: 'Defines if the badge is displayed overlapping the main element',
    },
  },
};
</script>

<style></style>
