<template>
  <v-col class="ma-0 pa-0" :cols="autoScaleWidth ? 'auto' : ''">
    <v-select v-bind="$attrs" @change="selectionChanged" v-model="actSelection" :style="getStyle" :color="getColor">
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-select>
  </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MexSelect',
  data() {
    return {
      actSelection: -1,
    };
  },
  props: {
    autoScaleWidth: {
      type: Boolean,
      default: false,
    },
    customCarret: {
      type: String,
      default: '',
    },
    selection: {
      type: Number,
      default: null,
    },
  },
  computed: {
    ...mapGetters('theme', ['getDarkModeSetting', 'getActTheme']),
    getColor() {
      if (this.getDarkModeSetting) {
        return '#FFFFFF';
      }
      return '#000000';
    },
    getStyle() {
      let styleSettings = '';
      styleSettings = `--fontColor: ${this.getColor};`;
      if (this.autoScaleWidth) {
        styleSettings += ' width:min-content;';
      }

      return styleSettings;
    },
  },
  methods: {
    setPreSelection() {
      if (this.selection !== null) {
        this.actSelection = this.selection;
        this.$emit('change', this.actSelection);
      }
    },
    selectionChanged() {
      this.$emit('change', this.actSelection);
    },
  },
  created() {
    this.setPreSelection();
  },
};
</script>

<style>
.v-list .v-list-item--active .v-list-item__title {
  color: var(--v-secondary-base);
}

.v-application .primary--text {
  color: var(--v-secondary-base);
  caret-color: var(--v-secondary-base);
}
</style>
