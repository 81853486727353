const basicTheme = require('./basic');
const ratsTheme = require('./rats');
const meditexTheme = require('./meditex');
const meditexLicenseServerColorTheme = require('./meditexLicenseServer');
const example1Theme = require('./example1');
const example2Theme = require('./example2');

function getLightColorSet(selectedColorTheme) {
  const basicLight = { ...basicTheme.default.light };
  // Fill the basic slots with the selected color theme colors
  Object.keys(basicLight).forEach((prop) => {
    if (selectedColorTheme[prop] !== undefined) {
      basicLight[prop] = selectedColorTheme[prop];
    }
  });

  // add the special colors to the basics
  Object.keys(selectedColorTheme).forEach((prop) => {
    if (basicLight[prop] === undefined) {
      basicLight[prop] = selectedColorTheme[prop];
    }
  });
  return basicLight;
}
function getDarkColorSet(selectedColorTheme) {
  const basicDark = { ...basicTheme.default.dark };
  Object.keys(basicDark).forEach((prop) => {
    if (selectedColorTheme[prop] !== undefined) {
      basicDark[prop] = selectedColorTheme[prop];
    }
  });

  // add the special colors to the basics
  Object.keys(selectedColorTheme).forEach((prop) => {
    if (basicDark[prop] === undefined) {
      basicDark[prop] = selectedColorTheme[prop];
    }
  });
  return basicDark;
}

module.exports = {
  basicTheme: {
    name: 'Basic',
    light: { ...basicTheme.default.light },
    dark: { ...basicTheme.default.dark },
  },
  ratsTheme: {
    name: 'Rats Color Theme',
    light: getLightColorSet(ratsTheme.default.light),
    dark: getDarkColorSet(ratsTheme.default.dark),
  },
  meditexTheme: {
    name: 'MedITEX Color Theme',
    light: getLightColorSet(meditexTheme.default.light),
    dark: getDarkColorSet(meditexTheme.default.dark),
  },
  meditexLicenseServerTheme: {
    name: 'MedITEX License Server Color Theme',
    light: getLightColorSet(meditexLicenseServerColorTheme.default.light),
    dark: getDarkColorSet(meditexLicenseServerColorTheme.default.dark),
  },
  example1Theme: {
    name: 'Example 1 Color Theme',
    light: getLightColorSet(example1Theme.default.light),
    dark: getDarkColorSet(example1Theme.default.dark),
  },
  example2Theme: {
    name: 'Example 2 Color Theme',
    light: getLightColorSet(example2Theme.default.light),
    dark: getDarkColorSet(example2Theme.default.dark),
  },
};
