<template>
  <mex-sheet :sheetPadding="[2, 2, 2, 2]">
    <v-row justify="center">
      <v-col v-if="icon" cols="auto" align-self="center">
        <v-icon v-if="icon" :x-small="iconSize === 'x-small'" :small="iconSize === 'small'"
                :large="iconSize === 'large'" :x-large="iconSize === 'x-large'">{{ icon }}</v-icon>
      </v-col>
      <v-col cols="auto" align-self="center">
        <mex-p v-bind="$attrs" fontSize="h5" :content="content" class="mb-0" alignment="center" />
      </v-col>
    </v-row>
  </mex-sheet>
</template>

<script>
export default {
  name: 'MexHeading',
  description: 'MedITEX Heading',
  props: {
    content: {
      type: String,
      default: '',
      description: 'Text of the heading',
    },
    icon: {
      type: String,
      default: null,
      description: 'Icon of the heading',
    },
    iconSize: {
      type: String,
      default: '',
      description: 'Icon size of the heading',
    },
  },
};
</script>

<style></style>
