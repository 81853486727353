import Vue from 'vue';
import Toast from 'vue-toastification';
import '../configs/MedITEX-Connect-Assets/vue-toastification.configs/specific.css';
import i18n from './i18n';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import lastContactEvaluation from './functions/lastContactEvaluation';
import deactivationStateEvaluation from './functions/deactivationStateEvaluation';
import dateFormatter from './functions/dateFormatter';
import localStorageManager from './functions/localStorageManager';
import userPermissions from './functions/userPermissions';

const options = {};
Vue.use(Toast, options);

// comment to start buildserver

const requireMexComponent = require.context(
  // The relative path of the components folder
  './components/MedITEX_Vue_Components/MexComponents',
  // Whether or not to look in subfolders
  false,
  // The regular expression used to match component filenames who starts with "My"
  /Mex[A-Z]\w+\.(vue|js)$/,
);

requireMexComponent.keys().forEach((fileName) => {
  // Get component config
  const componentConfig = requireMexComponent(fileName);
  // Get PascalCase name of component
  if (componentConfig.default.name) {
    const componentName = componentConfig.default.name;
    // Register component globally
    Vue.component(
      componentName,
      // Look for the component options on `.default`, which will
      // exist if the component was exported with `export default`,
      // otherwise fall back to module's root.
      componentConfig.default || componentConfig,
    );
  }
});

i18n.locale = store.getters['locales/selectedLocal'].selectedLocal;

Vue.config.productionTip = false;
Vue.prototype.$lastContactEvaluation = lastContactEvaluation;
Vue.prototype.$deactivationStateEvaluation = deactivationStateEvaluation;
Vue.prototype.$dateFormatter = dateFormatter;
Vue.prototype.$localStorageManager = localStorageManager;
Vue.prototype.$userPermissions = userPermissions;
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
