/**
 * Checks if a date string represents a timestamp past a certain amount of hours
 * @param {string} lastContact - date string of the last contact
 * @param {number} x - amount of hours the last contact needs to be in
 * @returns {boolean} - true if the last contact was within the x hours
 */
function lastContactWithinXh(lastContact, x) {
  if (!lastContact) {
    return false;
  }
  // create a timestamp of the lastContact date string
  const lastContactTimeStamp = new Date(lastContact.replace('Z', '')).getTime();
  // is the timestamp smaller than the current timestamp - x hours.
  //                                                        min  sec   ms
  return lastContactTimeStamp >= new Date().getTime() - x * 60 * 60 * 1000;
}

/**
 * Checks if a date string represents a timestamp within the last 24 hours
 * @param lastContact
 * @returns {boolean} - true if the date string represents a timestamp within the last 24 hours
 */
function lastContactWithin24h(lastContact) {
  return lastContactWithinXh(lastContact, 24);
}

/**
 * Checks if a date string represents a timestamp within the last 48 hours
 * @param lastContact
 * @returns {boolean} - true if the date string represents a timestamp within the last 48 hours
 */
function lastContactWithin48h(lastContact) {
  return lastContactWithinXh(lastContact, 48);
}

const lastContactEvaluation = {
  lastContactWithinXh,
  lastContactWithin24h,
  lastContactWithin48h,
};

module.exports = lastContactEvaluation;
