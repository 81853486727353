const colorBase = { prussianBlue: '#1c3144', rossoCorsa: '#d00000', selectiveYellow: '#ffba08', cadetBlueCrayola: '#a2aebb' };
const meditexColorTheme = {
  light: {
    primary: colorBase.prussianBlue,
    primaryAccent: colorBase.rossoCorsa,
    secondary: colorBase.selectiveYellow,
    secondaryAccent: colorBase.cadetBlueCrayola,
    background: colorBase.mint,
  },
  dark: {
    primary: colorBase.prussianBlue,
    primaryAccent: colorBase.rossoCorsa,
    secondary: colorBase.selectiveYellow,
    secondaryAccent: colorBase.cadetBlueCrayola,
    background: colorBase.smokyBlack,
  },
};

export default meditexColorTheme;
