import colors from 'vuetify/lib/util/colors';

const basicColorTheme = {
  light: {
    primary: colors.blue,
    primaryAccent: colors.blue.accent1,
    secondary: colors.green,
    secondaryAccent: colors.green.accent1,
    error: colors.red.lighten1,
    warning: colors.amber.base,
    success: colors.lightGreen.darken1,
    info: colors.blue.lighten4,
    background: colors.blueGrey.lighten1,
    backgroundHome: colors.blueGrey.lighten1,
  },
  dark: {
    primary: colors.blue.darken4,
    primaryAccent: colors.blue.darken2,
    secondary: colors.green.darken4,
    secondaryAccent: colors.green.darken2,
    error: colors.red.lighten1,
    warning: colors.amber.base,
    success: colors.lightGreen.darken1,
    info: colors.blue.lighten4,
    background: colors.blueGrey.darken4,
    backgroundHome: colors.blueGrey.darken4,
  },
};

export default basicColorTheme;
