import de from 'vuetify/lib/locale/de';
import en from 'vuetify/lib/locale/en';

const locales = {
  de: {
    // eslint-disable-next-line global-require
    json: { ...require('./de.json') },
    vuetify: de,
    description: 'Deutsch',
    iso: 'de',
  },
  gb: {
    // eslint-disable-next-line global-require
    json: { ...require('./en.json') },
    vuetify: en,
    description: 'English',
    iso: 'gb',
  },
};

export default locales;
