<template>
  <mex-card :title="cardTitle" :icon="cardIcon">
    <mex-data-table :headers="tableHeaders" :data="tableData" title=" ">
      <template v-slot:datatable-top>
        <v-switch class="mt-4" v-model="compareSelect" label="Compare Select" />
      </template>
      <template v-slot:item="{ item }">
        <tr :class="selectedRow1 === item.id || selectedRow2 === item.id ? 'primary' : ''" @click="rowClick(item)">
          <td v-for="header in tableHeaders" :key="header.text">
            {{ item[header.value] }}
          </td>
        </tr>
      </template>
    </mex-data-table>
    <v-row justify="center">
      <v-slide-y-transition>
        <v-col cols="5" v-if="selectedItem1">
          <mex-sheet color="white" :shaped="false" elevation="1" :rounded="true">
            <mex-p content="Detail Area" alignment="center" fontSize="h6" transforms="uppercase" />
            <v-row>
              <v-col cols="auto">
                <mex-p content="Item-ID:" fontWeight="bold" />
                <mex-p v-for="header in tableHeaders" :key="header.text" :content="header.text + ': '" fontWeight="bold" />
              </v-col>
              <v-col>
                <template v-for="itemKey in getSelectedItemProps(selectedItem1)">
                  <mex-p :content="selectedItem1[itemKey].toString()" :key="itemKey" />
                </template>
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-slide-y-transition>
      <v-slide-y-transition>
        <v-col cols="5" v-if="selectedItem2">
          <mex-sheet color="white" :shaped="false" elevation="1" :rounded="true">
            <mex-p content="Detail Area" alignment="center" fontSize="h6" transforms="uppercase" />
            <v-row>
              <v-col cols="auto">
                <mex-p content="Item-ID:" fontWeight="bold" />
                <mex-p v-for="header in tableHeaders" :key="header.text" :content="header.text + ': '" fontWeight="bold" />
              </v-col>
              <v-col>
                <template v-for="itemKey in getSelectedItemProps(selectedItem2)">
                  <mex-p :content="selectedItem2[itemKey].toString()" :key="itemKey" />
                </template>
              </v-col>
            </v-row>
          </mex-sheet>
        </v-col>
      </v-slide-y-transition>
    </v-row>
  </mex-card>
</template>

<script>
export default {
  name: 'MexDetailTable',
  props: {
    cardTitle: {
      type: String,
      default: '',
      description: 'Title of the card surrounding the detail table',
    },
    cardIcon: {
      type: String,
      default: '',
      description: 'Icon if the card surrounding the detail card',
    },
    tableHeaders: {
      type: Array,
      default: () => [],
      description: 'Detail table headers (Array)',
    },
    tableData: {
      type: Array,
      default: () => [],
      description: 'Detail table data (Array)',
    },
    itemIdKey: {
      type: String,
      default: '',
      description: 'Item property which is unique for each item',
      required: true,
    },
  },
  data() {
    return {
      compareSelect: false,
      selectedItem1: null,
      selectedItem2: null,
      selectedRow1: -1,
      selectedRow2: -1,
      rewindDetailData: true,
    };
  },
  watch: {
    compareSelect() {
      this.refresh();
    },
  },
  methods: {
    refresh() {
      this.selectedRow1 = -1;
      this.selectedRow2 = -1;
      this.selectedItem1 = null;
      this.selectedItem2 = null;
    },
    getSelectedItemProps(item) {
      return Object.keys(item);
    },
    rowClick(item) {
      if (this.rewindDetailData) {
        this.selectedItem1 = item;
        this.selectedRow1 = item[this.itemIdKey];
        if (this.compareSelect) {
          this.rewindDetailData = false;
        }
      } else {
        this.selectedItem2 = item;
        this.selectedRow2 = item[this.itemIdKey];
        this.rewindDetailData = true;
      }
    },

    // getRowClass(item) {
    //   if (this.compareSelect) {
    //     return this.selectedRows.indexOf(item[this.itemIdKey]) > -1 ? 'primary' : '';
    //   }
    //   return this.selectedRows[0] === item[this.itemIdKey] ? 'primary' : '';
    // },
  },
};
</script>

<style></style>
