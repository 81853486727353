const colorBase = { darkLiver: '#54494b', grayWeb: '#7e8287', morningBlue: '#9da39a', oldRose: '#b98389' };
const meditexColorTheme = {
  light: {
    primary: colorBase.darkLiver,
    primaryAccent: colorBase.grayWeb,
    secondary: colorBase.morningBlue,
    secondaryAccent: colorBase.oldRose,
    background: colorBase.isbelline,
  },
  dark: {
    primary: colorBase.darkLiver,
    primaryAccent: colorBase.grayWeb,
    secondary: colorBase.morningBlue,
    secondaryAccent: colorBase.oldRose,
    background: colorBase.smokyBlack,
  },
};

export default meditexColorTheme;
