<template>
  <div>
    <v-treeview v-if="!enableContextMenu" :expand-icon="expandIcon" :items="treeviewData" item-key="id">
      <template v-if="enableElementIcons" v-slot:prepend="{ item }">
        <v-icon>
          {{ item.icon }}
        </v-icon>
      </template>
    </v-treeview>
    <v-treeview v-if="enableContextMenu" :expand-icon="expandIcon" :items="treeviewData" item-key="id">
      <template slot="label" slot-scope="{ item }">
        <div @contextmenu="setContextMenu(item, $event)">{{ item.name }}</div>
      </template>
      <template v-if="enableElementIcons" v-slot:prepend="{ item }">
        <v-icon>
          {{ item.icon }}
        </v-icon>
      </template>
    </v-treeview>
    <v-menu v-if="enableContextMenu" v-model="showContextMenu" :position-x="cmPosX" :position-y="cmPosY" absolute offset-y>
      <v-list :color="contextMenuColor">
        <v-list-item v-for="(cmItem, index) in contextMenuItems" :key="index" @click="$emit(cmItem.clickEvent, selectedElement)">
          <v-list-item-icon>
            <v-icon>
              {{ cmItem.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ cmItem.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

export default {
  name: 'MexTreeview',
  props: {
    jsonData: {
      type: Object,
      default: null,
      description: 'Treeview data in JSON format',
    },
    jsonDataRoot: {
      type: String,
      default: '',
      description: 'Name of the root element of the treeview if data is provided as json',
    },
    formattedTreeviewData: {
      type: Array,
      default: () => [],
      description: 'Treeview data preformatted as treeview specific array',
    },
    expandIcon: {
      type: String,
      default: 'mdi-arrow-down-drop-circle',
      description: 'Icon for expandable elements (should face down)',
    },
    enableContextMenu: {
      type: Boolean,
      default: false,
      description: 'Defines if conetxt menu on treeview elements is available',
    },
    contextMenuItems: {
      type: Array,
      default: () => [],
      description: 'Items of the context menu',
    },
    contextMenuColor: {
      type: String,
      default: '',
      description: 'Defines the color of the context menu',
    },
    enableElementIcons: {
      type: Boolean,
      default: false,
      description: 'Defines if icons of elements are shown if provided',
    },
  },
  data() {
    return {
      treeviewData: [],
      idx: 0,
      showContextMenu: false,
      cmPosX: 0,
      cmPosY: 0,
      selectedElement: null,
    };
  },
  created() {
    if (this.formattedTreeviewData.length > 0) {
      this.treeviewData = this.formattedTreeviewData;
    } else {
      this.treeviewData.push(this.convertToTreeviewData());
    }
  },
  methods: {
    is(type, subject) {
      // Example: [object String] -> "[object " + classOfSubject + "]"
      const classOfSubject = Object.prototype.toString.call(subject).slice(8, -1);
      // check if not undefined and not null and type as classOfSubject
      return classOfSubject !== undefined && (classOfSubject !== null) && (classOfSubject === type);
    },
    convertToTreeviewData() {
      if (this.jsonData) {
        if (this.isValue(this.jsonData)) {
          return this.transformValue(this.jsonData, this.jsonDataRoot, '');
        }
      }

      return this.transformObject(this.jsonData, this.jsonDataRoot, '');
    },
    getIdx() {
      return this.idx++;
    },
    generateRootPath(topPropPath, topProp) {
      return topPropPath === '' ? topProp : [topPropPath, topProp].join('.');
    },
    isValue(value) {
      return !this.is('Object', value) && !this.is('Array', value);
    },
    isObject(value) {
      return this.is('Object', value);
    },
    isArray(value) {
      return this.is('Array', value);
    },
    transformValue(iValue, topProp, topPropPath) {
      return {
        id: this.getIdx(),
        name: [topProp, iValue].join(': '),
        key: topProp,
        value: iValue,
        rootPath: this.generateRootPath(topPropPath, topProp),
      };
    },
    transformObject(iObject, topProp, topPropPath) {
      return {
        id: this.getIdx(),
        name: topProp,
        children: this.getChildrenOfCollection(iObject, this.generateRootPath(topPropPath, topProp)),
        rootPath: this.generateRootPath(topPropPath, topProp),
      };
    },
    transformArray(iArray, topProp, topPropPath) {
      return {
        id: this.getIdx(),
        name: topProp,
        children: this.getChildrenOfCollection(iArray, this.generateRootPath(topPropPath, topProp)),
        rootPath: this.generateRootPath(topPropPath, topProp),
      };
    },
    getChildrenOfCollection(collection, topPropPath) {
      return collection.map((value, keyOrIndex) => {
        if (this.isObject(value)) {
          return this.transformObject(value, keyOrIndex, topPropPath);
        }
        if (this.isArray(value)) {
          return this.transformArray(value, keyOrIndex, topPropPath);
        }
        if (this.isValue(value)) {
          return this.transformValue(value, keyOrIndex, topPropPath);
        }

        return 'NO DATA';
      });
    },
    setContextMenu(element, e) {
      e.preventDefault();
      this.showContextMenu = false;
      this.cmPosX = e.clientX;
      this.cmPosY = e.clientY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
      this.selectedElement = element;
    },
  },
};
</script>

<style></style>
