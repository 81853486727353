import Vue from 'vue';
import VueI18n from 'vue-i18n';
import locales from './locales/index';

Vue.use(VueI18n);

function getMessages() {
  const retObj = {};
  Object.keys(locales).forEach((local) => {
    retObj[local] = {
      ...locales[local].json,
      $vuetify: locales[local].vuetify,
    };
  });
  return retObj;
}

const messages = getMessages();

// const messages = {
//   en: {
//     // eslint-disable-next-line global-require
//     ...require('./locales/en.json'),
//     $vuetify: en,
//   },
//   de: {
//     // eslint-disable-next-line global-require
//     ...require('@/locales/de.json'),
//     $vuetify: de,
//   },
// };

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages,
});
