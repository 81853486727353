// Basic for a mex Header Card - reveal-actions passed by solt:passthrough-reveal-actions
<template>
  <mex-home-title-card :color="headerColor" :icon="false" hover-reveal>
    <template v-slot:heading>
      <v-row v-if="useHeaderImage" justify="center">
        <v-img :src="require(`../../../assets/${headerImage}`)" :max-height="headerImageHeight" :max-width="headerImageWidth" style="z-index: 0" />
      </v-row>
      <v-row v-else justify="center">
        <slot name="headerImage" />
      </v-row>
      <v-row justify="center">
        <v-col v-if="useHeaderIcon" cols="1">
          <v-icon large>{{ headerIcon }}</v-icon>
        </v-col>
        <!-- Just for spacing, maybe there is another way to do this -->
        <v-col v-if="useHeaderIcon" cols="1"> </v-col>
        <v-col v-if="useHeaderTitle" cols="auto">
          <p class="text-h1">{{ headerTitle }}</p>
        </v-col>
      </v-row>
      <v-row v-if="useHeaderSubtitle" justify="center">
        <p class="text-h3">{{ headerSubtitle }}</p>
      </v-row>
    </template>
    <template v-slot:reveal-actions>
      <slot name="reveal-actions" />
    </template>
  </mex-home-title-card>
</template>

<script>
export default {
  name: 'MexHeaderCard',
  components: {
    MexHomeTitleCard: () => import('./MexHomeTitleCard.vue'),
  },
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
    useHeaderTitle: {
      type: Boolean,
      default: true,
      description: '',
    },
    headerTitle: {
      type: String,
      default: 'This is a title',
    },
    useHeaderSubtitle: {
      type: Boolean,
      default: false,
    },
    headerSubtitle: {
      type: String,
      default: 'This is a subtitle',
    },
    useHeaderIcon: {
      type: Boolean,
      default: false,
    },
    headerIcon: {
      type: String,
      default: 'mdi-alien',
    },
    useHeaderImage: {
      type: Boolean,
      default: false,
    },
    headerImage: {
      type: String,
      default: '/no/path',
    },
    headerImageHeight: {
      type: Number,
      default: 310,
    },
    headerImageWidth: {
      type: Number,
      default: 310,
    },
  },
};
</script>

<style></style>
