<template>
  <v-dialog
    v-bind="$attrs"
    v-model="showDialog"
    transition="slide-x-transition"
    :persistent="persistentDialog"
    @click:outside="persistentDialog ? '' : $emit('click:outside')"
    class="pa-5"
  >
    <v-card :color="dialogColor">
      <v-card-title class="justify-center">
        {{ dialogTitle }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row class="mb-5" v-if="dialogText !== ''" justify="center">
          <v-col cols="auto">
            {{ dialogText }}
          </v-col>
        </v-row>
        <div :class="scrollbarLess ? 'scrollbarless pa-5' : 'pa-5'" v-else style="overflow-y: scroll; overflow-x: hidden; height: 500px">
          <!-- @slot Use this slot to display the mex-dialog content -->
          <slot name="dialog-content" />
        </div>
      </v-card-text>
      <v-card-actions class="pa-5" elevation="13">
        <v-row justify="center">
          <template v-if="dialogAcceptButtonTitle !== '' || dialogRejectButtonTitle !== ''">
            <template v-if="dialogAcceptButtonTitle">
              <v-col cols="auto">
                <v-btn class="acceptBtn ma-2" @click="$emit('accept')" :type="acceptOnSubmit ? 'submit' : ''">
                  {{ dialogAcceptButtonTitle }}
                </v-btn>
              </v-col>
            </template>
            <template v-if="dialogRejectButtonTitle">
              <v-col cols="auto">
                <v-btn class="rejectBtn ma-2" @click="$emit('reject')">
                  {{ dialogRejectButtonTitle }}
                </v-btn>
              </v-col>
            </template>
          </template>
          <template v-else>
            <mex-sheet elevation="16" color="transparent" :shaped="false" width="100%">
              <slot name="dialog-actions" />
            </mex-sheet>
          </template>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'MexDialog',
  description: 'MedITEX Dialog',
  props: {
    showDialog: {
      type: Boolean,
      default: false,
      description: 'Defines if dialog is shown or not',
    },
    dialogColor: {
      type: String,
      default: '',
      description: '',
    },
    dialogMaxWidth: {
      type: String,
      default: '310px',
      description: 'Defines max. width of dialog',
    },
    dialogTitle: {
      type: String,
      default: 'Dialog Title',
      description: 'Title of the dialog',
    },
    dialogText: {
      type: String,
      default: '',
      description: 'Text of the dialog',
    },
    dialogAcceptButtonTitle: {
      type: String,
      default: '',
      description: 'Title of the dialog button which emits accept event',
    },
    dialogRejectButtonTitle: {
      type: String,
      default: '',
      description: 'Title of the dialog button which emits reject event',
    },
    persistentDialog: {
      type: Boolean,
      default: true,
      description: 'Defines if a click outside of the dialog closes the dialog',
    },
    acceptOnSubmit: {
      type: Boolean,
      default: false,
      description: 'Defines if the accept button is of type submit',
    },
    scrollbarLess: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<style>
/* .acceptBtn::before {
  background-color: green;
}
.rejectBtn::before {
  background-color: red;
} */
.v-dialog {
  overflow: hidden;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbarless::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbarless {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
