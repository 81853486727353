<template>
  <p v-bind="$attrs" :v-on="$on" :class="computeClass">{{ content }}</p>
</template>

<script>
export default {
  name: 'MexP',
  description: 'MedITEX Paragraph',
  props: {
    content: {
      type: String,
      default: '',
      description: 'Content of the paragraph',
    },
    fontSize: {
      type: String,
      default: '',
      description: 'Defines the font size of the paragraph. (h1, h2, body-1, caption, ...)',
    },
    fontWeight: {
      type: String,
      default: '',
      description: 'Defines the font weight of the paragraph. (thin, light, bold, thin-italic, ...)',
    },
    fontColor: {
      type: String,
      default: '',
      description: 'Defines the color of the paragraph. (primary, error-light-1, red, blue-darken-1, ...)',
    },
    alignment: {
      type: String,
      default: '',
      description: 'Defines the alignment of the paragraph.(left, center, right, sm-right, ...)',
    },
    transforms: {
      type: String,
      default: '',
      description: 'Defines the transformation of the paragraph. (capitalize, lowercase, none, uppercase)',
    },
    opacity: {
      type: String,
      default: '',
      description: 'Defines the opacity of the paragraph. (high, medium, low)',
    },
  },
  computed: {
    computeClass() {
      let retString = 'text-';
      switch (this.fontSize) {
        case 'h1':
        case 'h2':
        case 'h3':
        case 'h4':
        case 'h5':
        case 'h6':
        case 'subtitle-1':
        case 'subtitle-2':
        case 'body-1':
        case 'body-2':
        case 'button':
        case 'caption':
        case 'overline':
          retString += this.fontSize;
          break;
        case '':
          retString += 'body-1';
          break;
        default:
          retString = this.fontSize;
          break;
      }

      switch (this.fontWeight) {
        case 'thin':
        case 'light':
        case 'regular':
        case 'medium':
        case 'bold':
        case 'black':
          retString += ' font-weight-';
          retString += this.fontWeight;
          break;
        case 'thin-italic':
        case 'light-italic':
        case 'regular-italic':
        case 'medium-italic':
        case 'bold-italic':
        case 'black-italic':
          retString += ' font-weight-';
          retString += this.fontWeight.replace('-', ' font-');
          break;
        case '':
        default:
          retString += this.fontWeight;
          break;
      }

      switch (this.alignment) {
        case 'left':
        case 'right':
        case 'center':
        case 'sm-left':
        case 'sm-right':
        case 'sm-center':
        case 'md-left':
        case 'md-right':
        case 'md-center':
        case 'lg-left':
        case 'lg-right':
        case 'lg-center':
        case 'xl-left':
        case 'xl-right':
        case 'xl-center':
          retString += ' text-';
          retString += this.alignment;
          break;
        case '':
        default:
          retString += '';
          break;
      }

      switch (this.transforms) {
        case 'capitalize':
        case 'lowercase':
        case 'none':
        case 'uppercase':
          retString += ' text-';
          retString += this.transforms;
          break;
        case '':
        default:
          retString += '';
          break;
      }

      switch (this.opacity) {
        case 'high':
          retString += ' text--';
          retString += 'primary';
          break;
        case 'medium':
          retString += ' text--';
          retString += 'secondary';
          break;
        case 'low':
          retString += ' text--';
          retString += 'disabled';
          break;
        case '':
        default:
          retString += '';
          break;
      }

      retString += ' ';
      if (this.fontColor !== '') {
        if (this.fontColor.includes('-')) {
          retString += `${this.fontColor.slice(0, this.fontColor.indexOf('-'))}--text`;
        } else {
          retString += `${this.fontColor}--text`;
        }
        if (this.fontColor.includes('-lighten')) {
          retString += ` ${this.fontColor.slice(this.fontColor.indexOf('-'), this.fontColor.length).replace('-lighten', ' text--lighten')}`;
        } else if (this.fontColor.includes('-darken')) {
          retString += ` ${this.fontColor.slice(this.fontColor.indexOf('-'), this.fontColor.length).replace('-darken', ' text--darken')}`;
        }
      }

      return retString;
    },
  },
};
</script>

<style></style>
