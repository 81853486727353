<template>
  <div v-bind="$attrs" v-on="$listeners" :class="computedClass" :style="computedBlinkColor" />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MexStatusLamp',
  description: 'MedITEX Status Lamps',
  props: {
    color: {
      type: String,
      default: 'red',
      description: 'Defines the color of the lamp',
    },
    blink: {
      type: Boolean,
      default: false,
      description: 'Defines if the lamp is blinking',
    },
    tooltip: {
      type: String,
      default: '',
      description: 'Tooltip for the lamp',
    },
  },
  computed: {
    ...mapGetters('theme', ['getDarkModeSetting', 'getActTheme']),
    computedClass() {
      return `sphere ${this.color}Lamp ${this.blink ? 'blink' : ''}`;
    },
    computedBlinkColor() {
      if (this.blink) {
        const selectedColorSet = this.getActTheme[this.getDarkModeSetting ? 'dark' : 'light'];
        const selectedColor = selectedColorSet[this.color];
        return `--blinkColor: ${selectedColor}`;
      }
      return '';
    },
  },
};
</script>

<style scoped>
.blink {
  animation: blink-animation 1s steps(5, start) infinite;
  -webkit-animation: blink-animation 1s steps(5, start) infinite;
}
@keyframes blink-animation {
  0% {
    box-shadow: inset 0px 0px 5px black, 1px 1px 2px black, 1px 1px 2px var(--blinkColor), 0px 0px 10px var(--blinkColor);
  }
  50% {
    box-shadow: inset -1px -1px 0px #000, inset 0px 0px 5px black;
  }
  100% {
    box-shadow: inset 0px 0px 5px black, 1px 1px 2px black, 1px 1px 2px black, 1px 1px 2px var(--blinkColor), 0px 0px 10px var(--blinkColor);
  }
}
@-webkit-keyframes blink-animation {
  0% {
    box-shadow: inset 0px 0px 5px black, 1px 1px 2px black, 1px 1px 2px var(--blinkColor), 0px 0px 10px var(--blinkColor);
  }
  50% {
    box-shadow: none;
  }
  100% {
    box-shadow: inset 0px 0px 5px black, 1px 1px 2px black, 1px 1px 2px var(--blinkColor), 0px 0px 10px var(--blinkColor);
  }
}

.errorLamp {
  background-color: var(--v-error-lighten1);
  box-shadow: inset -1px -1px 0px #000, 1px 1px 2px black, inset 0px 0px 5px black, 1px 1px 2px red, 0px 0px 10px rgb(116, 0, 0);
}
.successLamp {
  background-color: var(--v-success-lighten1);
  box-shadow: inset -1px -1px 0px #000, 1px 1px 2px black, inset 0px 0px 5px black, 1px 1px 2px green, 0px 0px 10px green;
}

.warningLamp {
  background-color: var(--v-warning-lighten1);
  box-shadow: inset -1px -1px 0px #000, 1px 1px 2px black, inset 0px 0px 5px black, 1px 1px 2px yellow, 0px 0px 10px yellow;
}

.sphere {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  text-align: center;
  vertical-align: middle;
  font-size: 500%;
  position: relative;
  display: inline-block;
  margin: 5%;
}
.sphere::after {
  background-color: rgba(255, 255, 255, 0.3);
  content: '';
  height: 45%;
  width: 12%;
  position: absolute;
  top: 4%;
  left: 15%;
  border-radius: 50%;
  transform: rotate(40deg);
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
