import NotificationsService from '../services/notifications.service';

export const badges = {
  namespaced: true,
  state: {
    notificationBadge: 0,
  },
  mutations: {
    setNotificationBadge(state, { cnt, toast }) {
      if (state.notificationBadge === cnt) {
        state.notificationBadge = cnt;
      } else {
        if (cnt - state.notificationBadge > 0 && toast) {
          if (cnt - state.notificationBadge > 1) {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$toast.info(`You have ${cnt - state.notificationBadge} new notifications`);
          } else {
            // eslint-disable-next-line no-underscore-dangle
            this._vm.$toast.info(`You have 1 new notification`);
          }
        }
        state.notificationBadge = cnt;
      }
    },
  },
  getters: {
    getNotificationBadge(state) {
      return state.notificationBadge;
    },
  },
  actions: {
    fetchNotifications({ commit }, { systemUserID, toast }) {
      NotificationsService.getNotificationsCountBySystemUser(systemUserID)
        .then((notificationCountResponse) => {
          commit('setNotificationBadge', {
            cnt: notificationCountResponse.data.notificationCount,
            toast,
          });
        })
        .catch((err) => {
          // eslint-disable-next-line no-underscore-dangle
          this._vm.$toast.error(err);
        });
    },
  },
};
