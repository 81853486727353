<template>
  <div>
    <v-text-field color="primaryAccent" v-if="search" v-model="searchValue" :label="search" :prepend-icon="searchIcon" />
    <v-data-table
      :class="tableClass"
      v-bind="$attrs"
      :headers="headers"
      :items="data"
      :item-key="itemKey"
      :loading="isLoading"
      :loading-text="loadingText"
      :single-select="singleSelect"
      :search="searchValue"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      :multi-sort="multiSort"
      :disable-pagination="enableServerSidePagination"
    >
      <template v-slot:top>
        <v-toolbar v-if="title" flat>
          <v-row justify="start" class="ml-2">
            <v-toolbar-title v-if="title"> {{ title }}</v-toolbar-title>
          </v-row>
          <v-row justify="end" class="mr-2">
            <slot name="datatable-top" />
          </v-row>
        </v-toolbar>
      </template>
      <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
        <slot :name="slot" v-bind="props" />
      </template>
    </v-data-table>
    <template v-if="enableServerSidePagination">
      <v-row justify="end" class="ma-2">
        <v-col cols="1">
          <v-select
            class="pa-0 pt-3"
            v-model="srvSideActSize"
            :items="srvSideSizeItems"
            :label="srvSideSizeLabel"
            @input="emitSizeChange"
            hide-details
            dense
          />
        </v-col>
        <v-col cols="1">
          <v-text-field class="pa-0 pt-3" label="Total items" v-model="srvSideTotalItems" dense hide-details />
        </v-col>
        <v-col cols="auto">
          <v-pagination v-model="srvSideActPage" :length="srvSideTotalPages" @input="emitPageChange" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MexDataTable',
  props: {
    tableClass: {
      type: String,
      default: '',
      description: '',
    },
    title: {
      type: String,
      default: '',
      description: 'Title of the data table',
    },
    headers: {
      type: Array,
      default: () => [],
      description: 'Headers of the data-table (Array)',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Data of the data-table (Array)',
    },
    itemKey: {
      type: String,
      default: '',
      description: 'Defines which property of the data items is used as key',
    },
    isLoading: {
      type: Boolean,
      default: false,
      description: 'Defines if loading spinner is active or not',
    },
    loadingText: {
      type: String,
      default: 'LOADING DATA ...',
      description: 'Text shown while loading',
    },
    singleSelect: {
      type: Boolean,
      default: false,
      description: 'Defines if single selection is enabled',
    },
    sortBy: {
      type: Array,
      default: () => [],
      description: 'Defines the headers which the table will be sorted after',
    },
    sortDesc: {
      type: Array,
      default: () => [],
      description: 'Defines in which order the sort will be executed',
    },
    multiSort: {
      type: Boolean,
      default: false,
      description: 'Defines if multiSort is enabled',
    },
    search: {
      type: String,
      default: '',
      description: 'Label for search text field',
    },
    searchIcon: {
      type: String,
      default: 'mdi-magnify',
      description: 'Icon for search text field',
    },
    showExpand: {
      type: Boolean,
      default: false,
      description: 'Defines if table rows are expandable',
    },
    singleExpand: {
      type: Boolean,
      default: false,
      description: 'Defines if only one table row can be expanded',
    },
    expanded: {
      type: Array,
      default: () => [],
      description: '',
    },
    enableServerSidePagination: {
      type: Boolean,
      default: false,
      description: 'Defines if a custom footer with server side pagination',
    },
    srvSideActSize: {
      type: Number,
      default: 5,
      description: '',
    },
    srvSideSizeItems: {
      type: Array,
      default: () => [
        {
          text: '5',
          value: 5,
        },
        {
          text: '10',
          value: 10,
        },
        {
          text: '15',
          value: 15,
        },
        {
          text: 'All',
          value: -1,
        },
      ],
    },
    srvSideSizeLabel: {
      type: String,
      default: 'Rows per page',
    },
    srvSideActPage: {
      type: Number,
      default: 5,
      description: '',
    },
    srvSideTotalPages: {
      type: Number,
      default: 0,
    },
    srvSideTotalItems: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      searchValue: '',
    };
  },
  mounted() {
    this.emitSizeChange();
  },
  methods: {
    emitSizeChange() {
      this.$emit('sizeChanged', this.srvSideActSize);
    },
    emitPageChange() {
      this.$emit('pageChanged', this.srvSideActPage);
    },
  },
};
</script>

<style></style>
