<template>
  <v-container>
    <v-row justify="center">
      <v-progress-circular indeterminate :color="spinnerColor" :size="spinnerSize"> </v-progress-circular>
    </v-row>
    <v-row justify="center">
      <v-chip class="ma-2" :color="spinnerColor" label text-color="white">
        {{ spinnerText }}
      </v-chip>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MexSpinner',
  props: {
    spinnerColor: String,
    spinnerText: String,
    spinnerSize: {
      type: Number,
      default: 30,
    },
  },
};
</script>

<style></style>
