/**
 * Checks if a forced deactivation date is set
 * @param forceDeactivationDate
 * @returns {boolean} - ture if a forced deactivation date is set
 */
function isForcedDeactivationSet(forceDeactivationDate) {
  return !!forceDeactivationDate;
}

/**
 * Checks if a forced deactivation date is set and if its is already past the current date
 * @param forceDeactivationDate - date string from the database
 * @returns {boolean} - true if the is a forced deactivation date set which is past the current date
 */
function isDeactivated(forceDeactivationDate) {
  // check if a forced deactivation date is set
  if (isForcedDeactivationSet(forceDeactivationDate)) {
    // genrerate a timestamp from the forced deactiavtion date
    const isForcedDeactivationTimeStamp = new Date(forceDeactivationDate.replace('Z', '')).getTime();
    // check if the current timestamp is greater than the forced deactivation date timestamp
    return isForcedDeactivationTimeStamp <= new Date().getTime();
  }
  return false;
}

const deactivationStateEvaluation = {
  isForcedDeactivationSet,
  isDeactivated,
};

module.exports = deactivationStateEvaluation;
