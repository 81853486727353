import themes from '../../../configs/MedITEX-Connect-Assets/color.configs';

const localStorageManager = require('../../functions/localStorageManager');

const initialState = () => {
  const localStorageItem = JSON.parse(localStorageManager.readFromStorage('themeData'));
  if (localStorageItem) {
    return localStorageItem;
  }
  // load specific theme or basic theme
  const themeName = process.env.VUE_APP_THEME_NAME || 'basicTheme';
  localStorageManager.writeToStorage(
    'themeData',
    JSON.stringify({
      darkMode: true,
      actTheme: themes[themeName],
    }),
  );
  return { darkMode: true, actTheme: themes[themeName] };
};

export const theme = {
  namespaced: true,
  state: initialState,
  mutations: {
    switchDarkMode(state, darkMode) {
      state.darkMode = darkMode;
      localStorageManager.writeToStorage('themeData', JSON.stringify(state));
    },
    setActTheme(state, actTheme) {
      state.actTheme = actTheme;
      localStorageManager.writeToStorage('themeData', JSON.stringify(state));
    },
  },
  getters: {
    getDarkModeSetting(state) {
      return state.darkMode;
    },
    getActTheme(state) {
      return state.actTheme;
    },
  },
};
