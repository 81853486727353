<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="auto">
        <div class="parent">
          <img
            class="spinnerCounter"
            v-bind:src="spinnerDarkMode ? require('./MexComponents.assets/MexSpermSpinner/spermspinner_egg_white.png') : require('./MexComponents.assets/MexSpermSpinner/spermSpinner_egg.png')"
            width="100px"
            alt="spermSpinner_Egg"
          />
          <img
            class="spinner"
            v-bind:src="spinnerDarkMode ? require('./MexComponents.assets/MexSpermSpinner/spermspinner_sperm_white.png') : require('./MexComponents.assets/MexSpermSpinner/spermSpinner_sperm.png')"
            width="100px"
            alt="spermSpinner_Sperm"
          />
        </div>
      </v-col>
    </v-row>
    <v-row v-if="spinnerText !== ''" justify="center">
      <v-col cols="auto">
        <div class="loading">
          <mex-p :fontColor="spinnerDarkMode ? 'white' : 'black'" :content="spinnerText"/>
          <mex-p
            :fontColor="spinnerDarkMode ? 'white' : 'black'"
            class="loading__letter ml-1"
            :style="getAnimationDelay(idx)"
            v-for="(char, idx) in spinnerDots"
            :key="idx"
            :content="char"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'MexSpermSpinner',
  props: {
    spin: {
      type: Boolean,
      default: false,
    },
    spinnerDarkMode: {
      type: Boolean,
      default: false,
    },
    spinnerText: {
      type: String,
      default: 'Loading',
    },
  },
  data() {
    return {
      spinnerDots: '. . .',
    };
  },
  computed: {
    ...mapGetters('theme', ['getDarkModeSetting']),
  },
  created() {
    this.spinnerDarkMode = this.getDarkModeSetting;
  },
  methods: {
    getAnimationDelay(idx) {
      return `animation-delay:0.${idx * 2}s;`;
    },
    getSpermImagePath() {
      if (this.spinnerDarkMode) {
        return 'spermspinner_sperm_white.png';
      }
      return 'spermspinner_sperm.png';
    },
  },
};
</script>

<style scoped>
.parent {
  position: relative;
  top: 0;
  left: 0;
}

.steady {
  position: relative;
  top: 0;
  left: 0;
  /* -webkit-filter: invert(1);
  filter: invert(1); */
}

.spinner {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: spin 1.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
  position: absolute;
  top: 0;
  left: 0;
  /* -webkit-filter: invert(1);
  filter: invert(1); */
}

.spinnerCounter {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: spinCounter 3s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
  top: 0;
  left: 0;
  /* -webkit-filter: invert(1);
  filter: invert(1); */
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

@keyframes spinCounter {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(100deg);
  }
  75% {
    transform: rotate(150deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.loading {
  display: flex;
  flex-direction: row;
}

.loading__letter {
  text-transform: uppercase;
  font-size: 1.5em;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: bounce 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  40% {
    transform: translateY(-5px);
  }
  80%,
  100% {
    transform: translateY(0px);
  }
}
</style>
