import httpClient from '../httpClient/httpClient';

const NotificationService = {
  getNotificationsBySystemUser(systemUserID, limit) {
    return httpClient.get('api/get-notifications-by-systemuser', {
      params: {
        systemUserID,
        limit,
      },
    });
  },
  getNotificationsCountBySystemUser(systemUserID) {
    return httpClient.get('api/get-notifications-count-by-systemuser', {
      params: {
        systemUserID,
      },
    });
  },
  deleteNotification(notificationID) {
    return httpClient.post('api/delete-notification', {
      notificationID,
    });
  },
  updateNotification(notificationID, markedRead) {
    return httpClient.post('api/update-notification', {
      notificationID,
      markedRead,
    });
  },
};

export default NotificationService;
