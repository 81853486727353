<template>
  <mex-card :hoverReveal="true" :color="color">
    <template v-slot:heading>
      <v-sparkline
        v-bind="$attrs"
        :value="data"
        :labels="labels"
        :color="chartColor"
        :height="height"
        padding="24"
        stroke-linecap="round"
        auto-draw
        auto-draw-duration="1000"
      />
    </template>
    <v-row>
      <v-col>
        <mex-p :content="chartCardTitle" fontSize="h6" />
      </v-col>
    </v-row>
    <slot name="chart-card-content" />
    <template v-slot:reveal-actions>
      <mex-btn :color="color" icon="mdi-cached" content="RESYNC" small />
    </template>
  </mex-card>
</template>

<script>
export default {
  name: 'MexChartCard',
  props: {
    color: {
      type: String,
      default: 'primary',
      description: 'Defines the color of the chartbackground',
    },
    chartColor: {
      type: String,
      default: 'secondaryAccent',
      description: 'Defines the color of the sparkline',
    },
    height: {
      type: String,
      default: '100',
      description: 'Defines the height of the chart',
    },
    data: {
      type: Array,
      default: () => [],
      description: 'Array containing data to render sparkline',
    },
    labels: {
      type: Array,
      default: () => [],
      description: 'Array containing labels for data',
    },
    chartCardTitle: {
      type: String,
      default: '',
      description: 'Defines the title of the chart card',
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
