<template>
  <v-tooltip v-if="tooltip || customTooltip" :bottom="tooltipBottomPos" :top="tooltipTopPos" :left="tooltipLeftPos" :right="tooltipRightPos">
    <template v-slot:activator="{ attrs, on }">
      <mex-badge
        class="mr-4"
        v-if="badgeContent && badgeValue"
        :content="badgeContent"
        :color="badgeColor"
        :value="badgeValue"
        :inline="false"
        :overlap="true"
      >
        <!-- [DaWo] !!!! IF YOU CHANGE SOMETHING HERE ALSO CHANGE IT FOR MEX BASIC BUTTON !!!! -->
        <v-btn
          v-bind="attrs"
          v-on="on"
          :icon="iconOnly"
          :color="color"
          @click="$emit('click')"
          @mouseover="$emit('mouseover')"
          @mouseleave="$emit('mouseleave')"
          class="ml-1 mr-1"
        >
          <v-icon v-if="icon" class="mr-2" :small="small"> {{ icon }}</v-icon>
          {{ iconOnly || content === '' ? '' : content }}
        </v-btn>
      </mex-badge>
      <!-- [DaWo] !!!! IF YOU CHANGE SOMETHING HERE ALSO CHANGE IT FOR MEX BASIC BUTTON !!!! -->
      <v-btn
        v-else
        v-bind="attrs"
        v-on="on"
        :icon="iconOnly"
        :color="color"
        @click="$emit('click')"
        @mouseleave="$emit('mouseleave')"
        @mouseover="$emit('mouseover')"
        class="ml-1 mr-1"
      >
        <v-icon v-if="icon" class="mr-2" :small="small"> {{ icon }}</v-icon>
        {{ iconOnly || content === '' ? '' : content }}
      </v-btn>
    </template>
    <span v-if="!smallTooltip && !customTooltip">{{ tooltip }}</span>
    <span v-else-if="!customTooltip">
      <mex-p :content="tooltip" fontSize="caption" class="mb-0" />
    </span>
    <slot v-if="customTooltip" name="customTooltip" />
  </v-tooltip>
  <mex-badge
    class="mr-4"
    v-else-if="!tooltip && badgeContent && badgeValue"
    :content="badgeContent"
    :color="badgeColor"
    :value="badgeValue"
    :inline="false"
    :overlap="true"
  >
    <mex-basic-btn
      v-bind="$attrs"
      :icon="icon"
      :iconOnly="iconOnly"
      @click="$emit('click')"
      @mouseover="$emit('mouseover')"
      @mouseleave="$emit('mouseleave')"
      :color="color"
      :content="content"
    />
  </mex-badge>
  <mex-basic-btn
    v-else
    v-bind="$attrs"
    :icon="icon"
    :iconOnly="iconOnly"
    @click="$emit('click')"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    :color="color"
    :content="content"
  />
</template>

<script>
export default {
  name: 'MexBtn',
  description: 'MedITEX Button',
  props: {
    content: {
      type: String,
      default: 'Click Me',
      description: 'Content of the button',
    },
    icon: {
      type: String,
      default: '',
      description: 'Icon of the button',
    },
    iconOnly: {
      type: Boolean,
      default: false,
      description: 'Defines if only the icon or the content and the icon is shown',
    },
    color: {
      type: String,
      default: '',
      description: 'Color of the button',
    },
    tooltip: {
      type: String,
      default: '',
      description: 'Tooltip of the button',
    },
    smallTooltip: {
      type: Boolean,
      default: false,
      description: 'Defines if a smaller version of the tooltip will be displayed',
    },
    tooltipPosition: {
      type: String,
      default: 'bottom',
      description: 'Defines on which position around the button the tooltip is defined',
    },
    customTooltip: {
      type: Boolean,
      default: false,
      description: 'Defines if a by slot provided tooltip will be displayed',
    },
    badgeContent: {
      type: String,
      default: '',
      description: 'Content of the button badge',
    },
    badgeValue: {
      type: Number,
      default: 0,
      description: 'Value of the button badge',
    },
    badgeColor: {
      type: String,
      default: 'secondaryAccent',
      description: 'Color of the button badge',
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipBottomPos: false,
      tooltipTopPos: false,
      tooltipLeftPos: false,
      tooltipRightPos: false,
    };
  },
  methods: {
    getTooltipPos() {
      switch (this.tooltipPosition) {
        case 'bottom':
          this.tooltipBottomPos = true;
          break;
        case 'top':
          this.tooltipTopPos = true;
          break;
        case 'left':
          this.tooltipLeftPos = true;
          break;
        case 'right':
          this.tooltipRightPos = true;
          break;
        default:
          this.tooltipBottomPos = true;
          break;
      }
    },
  },
  created() {
    this.getTooltipPos();
  },
};
</script>

<style></style>
