const colorBase = {
  blue: '#4E91C9',
  aero: '#82B2D9',
  oldMauve: '#56203D',
  magenta: '#86325F',
  richBlack: '#023436',
  queenPink: '#6e3553',
  isbelline: '#F7F4F3',
  smokyBlack: '#0C0908',
  light_green: '#bfdfb7',
  light_blue: '#b8d3e8',
  light_orange: '#f4e8d5',
  light_red: '#fcc2bf',
  dark_green: '#0B6E0F',
  dark_blue: '#0E4673',
  dark_orange: '#A56412',
  dark_red: '#AC0000',
};
const meditexColorTheme = {
  light: {
    primary: colorBase.blue,
    primaryAccent: colorBase.aero,
    secondary: colorBase.oldMauve,
    secondaryAccent: colorBase.magenta,
    background: colorBase.isbelline,
    success: colorBase.light_green,
    warning: colorBase.light_orange,
    error: colorBase.light_red,
    info: colorBase.light_blue,
    font: '#00000'
  },
  dark: {
    primary: colorBase.blue,
    primaryAccent: colorBase.richBlack,
    secondary: colorBase.oldMauve,
    secondaryAccent: colorBase.queenPink,
    background: colorBase.smokyBlack,
    success: colorBase.dark_green,
    warning: colorBase.dark_orange,
    error: colorBase.dark_red,
    info: colorBase.dark_blue,
    font: '#ffffff'
  },
};

export default meditexColorTheme;
